import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './routes/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import TreqListingPage from './pages/TreqListingPage';
import CreateTreqPage from './pages/CreateTreqPage';
import EditTreq from './pages/EditTreq';
import OldTreqListingPage from './pages/OldTreqListingPage';
import MonthlyReportsPage from './pages/MonthlyReportsPage';
import DetailReportsPage from './pages/DetailReportsPage';
import AddUserPage from './pages/AddUserPage';
import ManageUserPage from './pages/ManageUserPage';
import ManageGroupsPage from './pages/ManageGroupsPage';
import DetailTreq from './pages/DetailTreq';
import RootLayout from './layouts/Root';
//import ErrorPage from './layouts/Error';
import { ROLES } from './constants/roles';
import SessionExpiredModal from './components/SessionExpiredModal';
import { useAuth } from './utils/auth';
import UnderMaintenancePage from './pages/UnderMaintenancePage';

const App: React.FC = () => {

  const { user } = useAuth();

  const renderRoutes = () => {
    if (user?.SchedulinkInfo) {
      const { treqId } = user.SchedulinkInfo;
      return (
        <>
          <Route path="/" element={<Navigate replace to={treqId === 0 ? "/create-treq" : `/treq/edit/${treqId}`} />} />
          {treqId === 0
            ? <Route path="create-treq" element={<ProtectedRoute><CreateTreqPage /></ProtectedRoute>} />
            : <Route path="/treq/edit/:id" element={<ProtectedRoute><EditTreq /></ProtectedRoute>} />
          }
        </>
      );
    } else {
      return (
        <>
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route path="dashboard" element={<ProtectedRoute allowedRoles={[ROLES.APPLICATION_ADMIN, ROLES.STAFF_ADMIN, ROLES.DISPATCHER, ROLES.REQUESTOR]}><DashboardPage /></ProtectedRoute>} />
          <Route path="treq-listing" element={<ProtectedRoute allowedRoles={[ROLES.APPLICATION_ADMIN, ROLES.STAFF_ADMIN, ROLES.DISPATCHER, ROLES.REQUESTOR]}><TreqListingPage /></ProtectedRoute>} />
          <Route path="create-treq" element={<ProtectedRoute allowedRoles={[ROLES.APPLICATION_ADMIN, ROLES.REQUESTOR, ROLES.STAFF_ADMIN]}><CreateTreqPage /></ProtectedRoute>} />
          <Route path="/treq/edit/:id" element={<ProtectedRoute allowedRoles={[ROLES.APPLICATION_ADMIN, ROLES.STAFF_ADMIN, ROLES.DISPATCHER, ROLES.REQUESTOR]}><EditTreq /></ProtectedRoute>} />
          <Route path="/treq/detail/:id" element={<ProtectedRoute allowedRoles={[ROLES.APPLICATION_ADMIN, ROLES.STAFF_ADMIN, ROLES.DISPATCHER, ROLES.REQUESTOR]}><DetailTreq /></ProtectedRoute>} />
          <Route path="old-treq-listing" element={<ProtectedRoute allowedRoles={[ROLES.APPLICATION_ADMIN, ROLES.STAFF_ADMIN, ROLES.REQUESTOR]}><OldTreqListingPage /></ProtectedRoute>} />
          <Route path="reports/monthly" element={<ProtectedRoute allowedRoles={[ROLES.APPLICATION_ADMIN, ROLES.STAFF_ADMIN]}><MonthlyReportsPage /></ProtectedRoute>} />
          <Route path="reports/detail" element={<ProtectedRoute allowedRoles={[ROLES.APPLICATION_ADMIN, ROLES.STAFF_ADMIN, ROLES.DISPATCHER, ROLES.REQUESTOR]}><DetailReportsPage /></ProtectedRoute>} />
          <Route path="admin/add-user" element={<ProtectedRoute allowedRoles={[ROLES.APPLICATION_ADMIN]}><AddUserPage /></ProtectedRoute>} />
          <Route path="admin/manage-user" element={<ProtectedRoute allowedRoles={[ROLES.APPLICATION_ADMIN]}><ManageUserPage /></ProtectedRoute>} />
          <Route path="admin/manage-groups" element={<ProtectedRoute allowedRoles={[ROLES.APPLICATION_ADMIN]}><ManageGroupsPage /></ProtectedRoute>} />
        </>
      );
    }
  };


  return (
    <BrowserRouter>
      <SessionExpiredModal />
      {process.env.REACT_APP_UNDERMANITENANCE === 'true' ? (
        <Routes>
          <Route element={<RootLayout />}>
            <Route path="*" element={<UnderMaintenancePage />} />
          </Route>
        </Routes>

      ) : (
        <Routes>
          <Route element={<RootLayout />}>
            {renderRoutes()}
            <Route path="login" element={<LoginPage />} />
            <Route path="*" element={<LoginPage />} />
          </Route>
        </Routes>
      )}

    </BrowserRouter>
  );
};

export default App;