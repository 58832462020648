import { formatDateTime, combineDateTime, formatDate, formatTime } from "../utils/common";

export const createPayload = (values: any, actionType: number, isBrazilUser: boolean | number,treqId:number) => ({
  type: actionType,
  generalData: {
    requestorid: values.requestorid,
    requestid: treqId,
    treqId: treqId,
    project: values.projectId,
    dateNeeded: formatDate(values.dateNeeded),
    timeNeeded: formatTime(values.timeNeeded),
    activity: values.activity,
    projectMgr: values.projectManager,
    telephoneNumber: values.telephone,
    divisionCode: values.divisionCode,
    purposeoftrip: values.purposeoftrip || "",
    isBrazilUser: isBrazilUser ? 1 : 0,
    numberOfPassengers: values.passengers,
    numberOfParcels: values.parcels,
    backhaul: values.backhaul
  },
  flightData: !isBrazilUser
    ? {
        airlineCode: values.airlinecode,
        airwayBillNumber: "",
        flightNumber: values.fltnumber,
        flightTo: values.FlightTo,
        flightFrom: values.FlightFrom,
        flightArrivaltime: formatDateTime(combineDateTime(values.etaDate, values.etdTime)),
        flightDeparturetime: formatDateTime(combineDateTime(values.etaDate, values.etdTime)),
      }
    : undefined,
  travelData: !isBrazilUser ?{
    puStateId: values.pickupState,
    puCityId: values.pickupCity,
    locationId: values.pickupLocation,
    deliveryStateId: values.deliveryState,
    deliveryCityId: values.deliveryCity,
    deleiveryLocationId: values.deliveryLocation,
    nameOfPULocationContact: values.pickupContactName,
    telephoneOfPULocationContact: values.contactTelephone,
    otherPUCity: values.otherPickupCity,
    otherDeliveryCity: values.otherDeliveryCity,
    otherLocation: values.otherPickupLocation,
    otherDeliveryLocation: values.otherDeliveryLocation,
  }:undefined,
  vehicleData: !isBrazilUser
    ? {
        vehicleid: values.vehicleId,
        driverid: values.driverId,
        trailerid: values.trailerid,
        consolidationsId: values.consolidationsId,
        numberOfConsolidations: values.numberOfConsolidations,
        finalMiles: values.finalMiles,
        startingPointId: values.startingPointId,
        endPointId: values.endPointId,
        mileageAtPU: values.mileageAtPU,
        timeAtPU: formatTime(values.timeAtPU),
        waitTimeAtPU: values.waitTimeAtPU,
        mileageAtDeliveryLocation: values.mileageAtDeliveryLocation,
        timeAtDeliveryLocation: formatTime(values.timeAtDeliveryLocation),
        vehicleInspectionComments: values.vehicleInspectionComments,
        problems: values.problems,
        dispatcherid: "",
        beginningMiles: values.startingMileage,
        endingMiles: values.endingMiles,
        startTime: formatTime(values.startTime),
        endTime: formatTime(values.endTime),
        waitTimeAtDelivery: values.waitTimeAtDelivery,
        otherDriver: values.otherDriver,
      }
    : undefined,
    transportationData: isBrazilUser
    ? {
        voucher: values.voucher,
        transportationValue: values.transportationValue,
        operationsType: values.operationsType,
        corgoMoveEquiId: values.corgoMoveEquiId,
        location: values.location,
        projectDescription: values.projectDescription,
        notes: values.notes,
        COPQ: values.COPQ,
        origin: values.origin,
        destination: values.destination,
        urbanTransportationId: values.urbanTransportationId,
        urbanTransportDesc: values.urbanTransportDesc,
        longDistTransportationId:values.longDistTransportationId,
        longDistDesc: values.longDistDesc,
        createdDate: values.createdDate,
        selectedVal: values.selectedVal,
        ldtSelectedVal:values.ltdSelectedVal,
        nameOfPULocationContact:values.nameOfPULocationContact
      }
    : undefined,
  removeFileAttachments: (values.attachments?.remove || []).map((file) => ({
    attachmentId: file.attachmentId,
    requestId: file.requestId,
    originalFileName: file.originalFileName,
    guidFileName: file.guidFileName,
    filePath: "",
    fileSize: file.fileSize,
    createdDate: file.createdDate,
    createdDateString: file.createdDateString,
    createdBy: file.createdBy,
    status: 0,
  })),
});
