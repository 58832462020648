import React, { useEffect,useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { fetchLastModifiedData } from '../../features/dashboard/dashboardSlice';
import { RootState, AppDispatch } from '../../store';
import { Link } from 'react-router-dom';

const LastModifiedDataTable: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {lastModifiedData} = useSelector((state: RootState) => state.dashboard);

  useEffect(() => {
    dispatch(fetchLastModifiedData());
  }, [dispatch]);

  const renderTreqID = useCallback((rowData: any) => {
    return <Link to={`/treq/edit/${rowData.encryptedRequestID?encodeURIComponent(rowData.encryptedRequestID):rowData.requestid}`}>{rowData.requestid}</Link>;
  }, []);

  return (
    <div>
      <DataTable value={lastModifiedData} responsiveLayout="scroll" sortMode="multiple" >
        <Column field="requestid" header="T-Req. ID" sortable body={renderTreqID} />
        <Column field="requestor" header="Requestor" sortable />
        <Column field="drivername" header="Driver" sortable />
        <Column field="Status" header="Status" sortable />
      </DataTable>
    </div>
  );
};

export default LastModifiedDataTable;
