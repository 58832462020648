export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_BASE_URL_ON_PREM = process.env.REACT_APP_API_BASE_URL_ON_PREM ;
export const API_BASE_URL_AUTH = process.env.REACT_APP_API_BASE_URL_AUTH ;

export const ENDPOINTS = {
  GET_TREQ_STATUS: "dashboard/treqStatus",
  GET_DRIVERS_WITH_MAX_TREQ: "dashboard/maxTreq",
  GET_MONTHLY_TREQ: "dashboard/monthTreq",
  GET_TREQ_SUMMARY: "dashboard/totalTreq",
  GET_LAST_MODIFIED: "dashboard/lastModifedTreq",
  GET_DIVISIONS:"dashboard/divisionList",
  GET_BRAZILIAN_PROJECT_TREQ:"dashboard/projectTreq",
  GET_BRAZILIAN_PROJECT_QUANTITY:"dashboard/quantity",
  GET_BRAZILIAN_PROJECT_DIVISION_TREQ:"dashboard/divisionTreq",
  GET_SYSTEM_LIST:"systemList",
  GET_PROJECT_LIST:"project-master/views/project_master",
  GET_COUNTRY_LIST:"country-state/views/country_state_master?$select=country_code as countrycode,country_name as name&$groupby=country_code,country_name&$filter=country_code=",
  GET_STATE_LIST:"country-state/views/country_state_master?$select=country_code as countrycode,state_code as statecode,state_name as name&$filter=country_code='USA'" ,//"getMasterStateList"  
  GET_LOCATION_LIST:"master-location/views/dv_master_location" ,//"getMasterStateList"  
  GET_UDC_TYPE:"udc-type/views/dv_udc_type",
  GET_MASTER_ACTIVITY:"project-activity/views/project_activity",
  GET_CITY_LIST:"request/city",
  GET_DESTINATION_LIST :"request/destinations",
  GET_DRIVERS_LIST:"request/drivers",
  GET_TRAILERS_LIST:"request/trailers",
  GET_VEHICLES_LIST:"request/vehicles",
  GET_TRANSPORT_LIST:"request/transport",
  GET_EQUIPMENT_LIST:"request/equipments",
  GET_USER_DATA:"request/treq",
  GET_TREQ_DATA_SEARCH:"list/search",
  GET_TREQ_DATA:"list",
  USER_AUTH_CHECK:"auth/authenticate",
  GET_TREQ_DATA_EDIT:"request/detail?treqId=",
  GET_OLD_TREQ_DATA:"old/detail",
  GET_OLD_TREQ_DATA_DETAIL:"old/id?treqId=",
  GET_LIST_EXPORT:"list/export",
  GET_LOOKUP_DATA:"admin/user/lookup",
  GET_USER:"user",
  GET_ROLES:"admin/user/role",
  POST_USER_SAVE:"user/save",
  DELETE_GROUP:"admin/group/delete",
  DELETE_GROUP_MEMBER:"group/delete/member",
  ADD_GROUP_MEMBER:"admin/group/add/member",
  GET_ALL_GROUPS:"group/groups",
  GET_GROUP_MEMBER:"group/member",
  ADD_EDIT_GROUP:"group/save",
  GENERATE_DETAIL_REPORT:"report/detail",
  GENERATE_MONTHLY_REPORT:"report/month",
  FETCH_GROUPS:"admin/group",
  FETCH_GROUP_MEMBERS:"admin/group/member",
  SAVE_USER:"admin/user/save",
  SEARCH_USER:"request/search",
  GET_USERS:"admin/user",
  REGISTER_USER:"admin/user/register",
  ADD_GROUP:"admin/group/save",
  DELETE_GROUP_MEMBERS:"admin/group/delete/member",
  GET_ATTACHMENTS:"request/attachment",
  DOWNLOAD_ATTACHMENT:"request/attachment/download",
  DELETE_TREQ:"request",
  SAVE_TREQ:"request/save",
  SAVE_ATTACHMENT:"request/attachment/upload",
  REOPEN_TREQ:"request/reopen",
  UPLOAD_CODE_PAY:"request/logistics/upload"
};