import React from "react";

interface SelectComponentProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: { label: string; value: string }[];
  title: string;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
  value,
  onChange,
  options,
  title,
}) => {
  return (
    <select
      value={value}
      onChange={onChange}
      title={title}
      className="form-control"
    >
       <option value="">
            Select
          </option>
      {options && options.length > 0 ? (
        options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))
      ) : (
        <option value="">No options available</option>
      )}
    </select>
  );
};

export default SelectComponent;
