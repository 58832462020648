import React,{useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DatePickerField from '../components/DatePickerField';
import axiosInstance, { updateBaseUrl } from "../api/axiosInstance";
import { ENDPOINTS } from "../api/config";
import {formatDateAPI} from "../utils/common"
import { store } from '../store';
import { showNotification } from '../features/notification/notificationSlice';

const MonthlyReport: React.FC = () => {

  const [loading,setLoading]=useState(false);

  const initialValues = {
    startDate: '',
    endDate: ''
  };

  const validationSchema = Yup.object({
    startDate: Yup.date()
      .required('Required')
      .nullable(),
    endDate: Yup.date()
      .required('Required')
      .nullable()
      .min(Yup.ref('startDate'), 'To date must be later than from date'),
  });

  const handleGenerateReport = async (values: any) => {
    const { startDate, endDate } = values;
    const formattedStartDate= formatDateAPI(startDate);
    const formattedEndDate= formatDateAPI(endDate);
    setLoading(true);
    try {
      updateBaseUrl(0);
      const response = await axiosInstance.get(
        `${ENDPOINTS.GENERATE_MONTHLY_REPORT}`,{
        params:{ startDate:formattedStartDate, endDate:formattedEndDate },
         responseType: 'blob' }
      );
      const xlsxBlob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const url = window.URL.createObjectURL(xlsxBlob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      const filename = `MonthlyReport_${formattedStartDate}_${formattedEndDate}.xlsx`;
      tempLink.setAttribute("download", filename);

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      store.dispatch(showNotification({ message: "Failed to download XLSX: "+ error, type: 'error' }));
      
    }
    finally{
      setLoading(false)
    }
  };

  return (
    <div className="page-content overflow treq-table">
       {loading && (
        <div className="overlay">
          <div className="loading-icon"></div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <h3 className="page-title">Monthly Report </h3>
        </div>
      </div>
      <hr className="hr" />
      <div className="row">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleGenerateReport}>
          <Form className="form-horizontal">
            <div className="form-group">
              <DatePickerField label="From:" name="startDate"   className="col-sm-2" labelClassName='col-sm-1'   />
              </div>
              <div className="form-group">
              <DatePickerField label="To:" name="endDate"  className="col-sm-2" labelClassName='col-sm-1'   />
              </div>
              <div className="form-group">
              <label  className="control-label col-sm-1 fw-norm p-lr5"></label>
              <div className="col-sm-2">
                <button className="btn icn-search" type="submit" id="btnSearch">Generate Report</button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default MonthlyReport;
