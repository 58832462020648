import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';
import { ENDPOINTS } from '../../api/config';
import createDataFetchThunk from "../../api/createDataFetchThunk";
interface TreqData {
  requestid: number;
  requestorid: string;
  requestor: string;
  project: string;
  dateneeded: string;
  timeneeded: string;
  PUCity: string;
  DeliveryCity: string | null;
  Location: string;
  DeliveryLocation: string;
  Status: string;
  drivername: string;
  EncryptedRequestID: string;
  DateNeededString: string;
  NameOfPULocationContact: string;
  statusId: number;
  dispatcherName: string;
  IsUploadedToLogistic: boolean | null;
  datecreated: string;
  dispatcherid: string | null;
}

interface TreqState {
  data: TreqData[];
  loading: boolean;
  error: string | null;
  totalRecords: number;
}

const initialState: TreqState = {
  data: [],
  loading: false,
  error: null,
  totalRecords:0,
};



const fetchOldTreqFunction =  async ({
  fromDate, toDate, treqId, page, size,sortField,sortOrder,filters,fromScheduleLink, searchField,searchValue
}: { fromDate: string; toDate: string; treqId: string; page: number; size: number; filters: any ,sortField:any,sortOrder:any,fromScheduleLink:boolean,searchField:any,searchValue:any}) => {
  const response = await axiosInstance.get<{result:TreqData[],totalTreqCount:number}>(ENDPOINTS.GET_OLD_TREQ_DATA, {
    params: { startDate:fromDate, endDate:toDate, treqId, pageNumber:page, pageSize:size,sortColumn:sortField,sortOrder:sortOrder===1?1:0,fromScheduleLink, filters,searchColumn:searchField,searchText:searchValue }
  });
  return {
    data: response.data.result,
    totalRecords: response.data?.totalTreqCount || 0
  };
};

export const fetchOldTreqData = createDataFetchThunk(
  'treq/fetchTreqData',
  fetchOldTreqFunction
);

const oldTreqSlice = createSlice({
  name: 'oldTreq',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOldTreqData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchOldTreqData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.totalRecords = action.payload.totalRecords;
      state.loading = false;
    });
    builder.addCase(fetchOldTreqData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Failed to fetch data';
    });
  },
});

export default oldTreqSlice.reducer;