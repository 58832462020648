import React from 'react';
import {useAuth} from '../utils/auth'

const Header: React.FC = () => {

const {logOut , user, isAuthenticated} = useAuth();
  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row">
          <div className="header col-xs-12 p-lr0">
            <div className="col-xs-6">
              <h1 className="top15m">Transportation Request</h1>
            </div>{
                isAuthenticated && user && (
                  <div className="col-xs-12">
                            <ul className="top-links">
                                <li className="icn-welcome">Welcome, {user.userFullName}</li>
                                <li className="icn-logout">
                                    <a href="#logout" onClick={logOut}>LogOut</a>
                                </li>
                            </ul>
            </div>
                )}
          </div>
          <div className="col-xs-12 logo top15m"></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
