import axiosInstance, { handleAxiosError ,updateBaseUrl} from '../api/axiosInstance';
import { ENDPOINTS } from "../../src/api/config";


export const authService = {
  login: async (formData: { token: string } & Record<string, any>) => {
    try {
      updateBaseUrl(2);

     const {token,...otherParams}=formData;
      const response = await axiosInstance.get(
        `${ENDPOINTS.USER_AUTH_CHECK}`,
        { params:otherParams,
          headers:{
            Authorization: `Bearer ${token}`
          }
        }
      );
      return {...response.data,"token":token};
    } catch (error) {
      throw new Error(handleAxiosError(error));
    }
  },
};