import * as Yup from "yup";
import {
  GeneralInformationFormData,
  TransportationInformationFormData,
  FlightInformationFormData,
  VehicleInformationFormData
} from "../../api/types";


export type FormDataBrazil = GeneralInformationFormData & TransportationInformationFormData;
export type FormDataNonBrazil = GeneralInformationFormData & FlightInformationFormData & VehicleInformationFormData;

export const initialValuesBrazilUser : FormDataBrazil  = {
    projectId: "",
    activity: "",
    dateNeeded: null,
    divisionCode: "",
    projectManager: "",
    dateSubmitted:null,
    dateCreated:null,
    origin: "", 
    destination: "",
    timeNeeded: "",
    telephone: "",
    requestor: "",
    voucher: "",
    transportationValue: "",
    operationsType: "",
    corgoMoveEquiId: "",
    location: "",
    projectDescription: "",
    notes: "",
    COPQ: "",
    urbanTransportDesc: "",
    longDistDesc: "",
    createdDate: "",
    urbanTransportationId: [],
    transportationCompany:"",
    longDistTransportationId:[],
    longDistTransport: [],
    urbanTransportValues: {},
    longDistTransportValues: {},
    transportQuantityData:[]
  
  }
  
  export const initialValues :  FormDataNonBrazil =  {
    projectId: "",
    passengers: "",
    parcels: "",
    activity: "",
    dateNeeded: null,
    dateSubmitted:null,
    dateCreated:null,
    divisionCode: "",
    projectManager: "",
    backhaul: "",
    timeNeeded: "",
    telephone: "",
    requestor: "",
    flightNumber: "",
    puCity: "",
    vehicleNumber: "",
    airlinecode: undefined,
    fltnumber: undefined,
    FlightFrom: undefined,
    FlightTo: undefined,
    pickupState: undefined,
    pickupCity: undefined,
    otherPickupCity: "",
    deliveryState: undefined,
    deliveryCity: undefined,
    otherDeliveryCity: "",
    deliveryLocation: undefined,
    otherDeliveryLocation: "",
    vehicleId: undefined,
    driverId: undefined,
    otherDriver: "",
    trailerId: undefined,
    consolidationsId: undefined,
    noOfConsolidations: undefined,
    finalMiles: undefined,
    startingPointId: undefined,
    endPointId: undefined,
    timeAtPU: "",
    timeAtDeliveryLocation: "",
    vehicleInspectionComments: "",
    problems: "",
  };
  
  
  export const getValidationSchema = (isBrazilUser: any) => Yup.object().shape({
    projectId: Yup.string().required("Project ID is required"),
    activity: Yup.string().required("Activity is required"),
    divisionCode: Yup.string().required("Division is required"),
    telephone: Yup.string().required("Telephone is required"),
    ...(isBrazilUser
      ?  {
        origin: Yup.string().required("Origin is required"),
        destination: Yup.string().required("Destination is required"),
        }
      : {}),
  });