export const ROLES = {
  APPLICATION_ADMIN: 'Application Admin',
  STAFF_ADMIN: 'Staff Admin',
  DISPATCHER: 'Dispatcher',
  REQUESTOR: 'Requestor',
} as const;

export const ROLE_IDS = {
  APPLICATION_ADMIN: 1,
  DISPATCHER: 2,
  REQUESTOR: 3,
  STAFF_ADMIN: 4,
} as const;

export const getRoles = (type?: keyof typeof ROLES): { id: RoleId; label: UserRole }[] => {
  const allRoles = [
    { id: ROLE_IDS.APPLICATION_ADMIN, label: ROLES.APPLICATION_ADMIN },
    { id: ROLE_IDS.DISPATCHER, label: ROLES.DISPATCHER },
    { id: ROLE_IDS.STAFF_ADMIN, label: ROLES.STAFF_ADMIN },
    { id: ROLE_IDS.REQUESTOR, label: ROLES.REQUESTOR },
  ];

  if (type) {
    return allRoles.filter(role => role.label === ROLES[type]);
  }

  return allRoles;
};

export type UserRole = typeof ROLES[keyof typeof ROLES];
export type RoleId = typeof ROLE_IDS[keyof typeof ROLE_IDS];