import React, { useState, useEffect, useCallback } from "react";
import InputField from "./InputField";
import SelectField from "./SelectField";
import DatePickerField from "./DatePickerField";
import TextAreaField from "./TextAreaField";
import TimeField from "./TimeField";
import { RootState, AppDispatch } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { Project } from "../api/types";
import { ENDPOINTS } from "../api/config";
import axiosInstance, { updateBaseUrl } from "../api/axiosInstance";
import {
  fetchProjects,
  fetchActivities,
  resetProjects,
  resetActivities
} from "../features/users/formDataSlice";
import { AutoComplete } from "primereact/autocomplete";
import RequestorSearchModal from './RequestorSearchModal';
import { useFormikContext, ErrorMessage } from "formik";
import { useAuth } from '../utils/auth';
import { formatDate, getValueById } from "../../src/utils/common";

interface GeneralInformationProps {
  treqId: string | null;
  dateNeeded: string;
  dateSubmitted: string | null;
  readOnly: boolean;
  edit?: boolean
  isBrazilUser?: boolean;
  statusOfTreq?: number
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({ treqId, dateSubmitted, readOnly = false, edit = false, isBrazilUser = false, statusOfTreq = 0 }) => {
  const { systems } = useSelector((state: RootState) => state.dashboard);
  const { udcType } = useSelector((state: RootState) => state.formData);

  const { user } = useAuth();
  const userName = user?.userFullName;
  const employeeId = user?.employeeId;
  const dviList = udcType.filter(item => item.type === "TreqDivision")
    .map(item => ({
      divisionCode: item.udc,
      description: item.description
    }));

  useEffect(() => {
    if (values.requestor === '') {
      setFieldValue("requestor", userName);
      setFieldValue("requestorid", employeeId);
    }
    if (!treqId) {
      dispatch(resetProjects());
      dispatch(resetActivities());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatch = useDispatch<AppDispatch>();

  const { masterActivity: activities, projectslist: projects } = useSelector(
    (state: RootState) => state.formData
  );

  const { values, setFieldValue } = useFormikContext<any>();
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [value, setValue] = useState<string>(values.projectId);


  const searchProjects = useCallback((event: any) => {
    const filterValue = `project_id LIKE('%${event.query}%')`;
    const params = {
      $select: "project_id,project_name,customer_id,project_manager_id,project_manager_name",
      $filter: filterValue,
      $count: 20

    };
    dispatch(fetchProjects(params));
  }, [dispatch]);

  const getProject = useCallback((id: string) => {
    const filterValue = `project_id = '${id}'`;

    const params = {
      $select: "project_id,project_name,customer_id,project_manager_id,project_manager_name",
      $filter: filterValue,
      $count: 1

    };
    dispatch(fetchProjects(params)).unwrap()
      .then((response) => {
        setFieldValue("projectName", response[0]?.project_name);
        setFieldValue("projectManager", response[0]?.project_manager_name);

      })
  }, [dispatch, setFieldValue]);

  useEffect(() => {
    if (values.projectId) {
      const filterValue = `project_id='${values.projectId}'`;
      dispatch(fetchActivities({ $filter: filterValue }));
    }
  }, [dispatch, values.projectId]);

  useEffect(() => {
    if (getValueById(dviList, values.divisionCode, "divisionCode", "description") !== 'ROV') {
      setFieldValue('location', "");
    }
    // eslint-disable-next-line
  }, [values.divisionCode]);

  useEffect(() => {
    if (user?.SchedulinkInfo && user?.SchedulinkInfo?.treqId === 0) {
      const fetchTreqData = async () => {
        try {
          updateBaseUrl(0);
          const stringToQuery = user?.SchedulinkInfo ? `&fromScheduleLink=true&assignmentId=${user?.SchedulinkInfo.assignmentId}&type=${user?.SchedulinkInfo.type}` : '&fromScheduleLink=false';
          const response = await axiosInstance.get(
            `${ENDPOINTS.GET_TREQ_DATA_EDIT}0${stringToQuery}`
          );
          const data = response.data;
          setFieldValue('passengers', data.numberOfPassengers)
          setFieldValue('purposeoftrip', data.purposeoftrip)
          setFieldValue('projectManager', data.projectMgr)
          setFieldValue('dateNeeded', data.startDate)
          setFieldValue("requestor", data.requestor);
          setFieldValue("requestorid", data.requestorid);
        } catch (error) {
          console.error("Error fetching T-Req data", error);
        }
      };


      //this needs to be called when the type is travel 
      if (user?.SchedulinkInfo?.type === 'travel' && edit === false) {
        fetchTreqData();
      }
    }

    if (user?.SchedulinkInfo?.projectId) {
      const projectId = user?.SchedulinkInfo?.projectId;
      setFieldValue('projectId', projectId)
      if (edit === false) {
        getProject(projectId);
      }
      setValue(projectId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  const onProjectSelect = useCallback((e: any) => {
    setFieldValue("projectId", e.value.project_id);
    setFieldValue("projectName", e.value.project_name);
    setFieldValue("projectManager", e.value.project_manager_name);
    setValue(e.value.project_id);
  }, [setFieldValue]);

  const handleRequestorSelect = useCallback((requestor: any) => {

    setFieldValue("requestor", requestor.employee_name);
    setFieldValue("requestorid", requestor.employee_id);
    setModalVisible(false);
  }, [setFieldValue]);

  useEffect(() => {
    if (projects && projects.length === 1) {
      setValue(projects[0].project_id);
    }
    setFilteredProjects(projects);
  }, [projects]);

  return (
    <div
      id="genInfoId"
      className="form-horizontal white content-border padding15"
    >
      <fieldset>
        <legend>General Information</legend>
        <div className="form-group">
          <label className="control-label col-sm-1 fw-norm p-lr5">
            T-Req ID:
          </label>
          <div className="col-md-3">
            <label className="control-label fw-norm p-lr5 bld-txt">
              {treqId || ''}
            </label>
          </div>
          <label className="control-label col-sm-1 fw-norm p-lr5">
            Job / Project
            &nbsp;<span style={{ color: "red" }}>#</span>
          </label>

          <div className="col-sm-3">
            <AutoComplete
              value={value}
              suggestions={filteredProjects}
              completeMethod={searchProjects}
              field="project_name"
              itemTemplate={(item) => item.project_id}
              onSelect={onProjectSelect}
              placeholder="Select a Project"
              disabled={readOnly}
              onChange={(e) => setValue(e.value)}
            />
            {!values.projectId && <ErrorMessage name="projectId" component="div" className="help-block" />}
          </div>
          {!isBrazilUser && <InputField label="No. of Passengers" name="passengers" numeric readOnly={readOnly} />}
        </div>
        {isBrazilUser &&
          <div className="form-group">
            <label className="control-label col-sm-1 fw-norm p-lr5">Date Created</label>
            <div className="col-md-3">
              <label className="control-label fw-norm p-lr5 bld-txt"> {dateSubmitted ? formatDate(values.dateCreated) : ''}</label>
            </div>
            <label className="control-label col-sm-1 fw-norm p-lr5">Project Description</label>
            <div className="col-sm-3" style={{ lineHeight: '29px' }}>{values.projectName}</div>

          </div>
        }
        <div className="form-group">
          <label className="control-label col-sm-1 fw-norm p-lr5">
            Submitted Date:
          </label>
          <div className="col-md-3">
            <label className="control-label fw-norm p-lr5 bld-txt"> {dateSubmitted ? formatDate(dateSubmitted) : ''}</label>
          </div>
          <SelectField
            label="Activity"
            name="activity"
            options={activities}
            valueField="activity_id"
            labelField="activity_id"
            readOnly={readOnly}
            required={true}
          />
          {!isBrazilUser && <InputField label="No. of Parcels" name="parcels" numeric readOnly={readOnly} />}
        </div>
        <div className="form-group">
          <DatePickerField
            label="Date Needed"
            name="dateNeeded"
            dateFormat="yy-mm-dd"
            readOnly={readOnly}
          />
          <InputField label="Project Mgr:" name="projectManager" readOnly={readOnly} />
          {!isBrazilUser && <InputField label="Backhaul:" name="backhaul" readOnly={readOnly} />}
        </div>
        {isBrazilUser &&
          <div className="form-group">
            <label className="control-label col-sm-1 fw-norm p-lr5"></label>
            <div className="col-sm-3"></div>
            <InputField label="Origin:" required={true} name="origin" readOnly={readOnly} />
            <InputField label="Destination:" required={true} name="destination" readOnly={readOnly} />
          </div>
        }
        <div className="form-group">
          <TimeField label="Time Needed" name="timeNeeded" readOnly={readOnly} />
          <InputField label="Telephone" name="telephone" maskPhone isBrazil={isBrazilUser} readOnly={readOnly} required={true} />
          <SelectField
            label="Division"
            name="divisionCode"
            options={dviList}
            valueField="divisionCode"
            labelField="description"
            readOnly={readOnly}
            required={true}
          />
        </div>

        {(isBrazilUser && getValueById(dviList, values.divisionCode, "divisionCode", "description") === 'ROV') &&
          <div className="form-group">
            <label className="control-label col-sm-1 fw-norm p-lr5"></label>
            <div className="col-sm-3"></div>
            <label className="control-label col-sm-1 fw-norm p-lr5"></label>
            <div className="col-sm-3"></div>
            <SelectField
              label="System"
              name="location"
              options={systems}
              valueField="location"
              labelField="name"
              readOnly={readOnly}
            />
          </div>
        }
        <div className="form-group">
          <InputField
            label="Requestor:"
            name="requestor"
            className="col-sm-2"
            readOnly={true}
          />
          <div className="col-sm-1">
            <button className="btn btn-default" disabled={edit && statusOfTreq !== 1} type="button" onClick={() => setModalVisible(true)}>...</button>
          </div>
          <TextAreaField label="Purpose of T-REQ" name="purposeoftrip" rows={6} readOnly={readOnly} maxlength={1000} />
        </div>
      </fieldset>
      <RequestorSearchModal visible={modalVisible} onHide={() => setModalVisible(false)} onSelect={handleRequestorSelect} />
    </div>
  );
};

export default GeneralInformation;
