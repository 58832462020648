import { PublicClientApplication} from '@azure/msal-browser';

export const msalConfig = {
  protectedResourceMap:new Map<string, Array<string>>().set('https://graph.microsoft.com/v1.0/me', ['user.read']),
  auth: {
    clientId:process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/'+ process.env.REACT_APP_MSAL_TENANT_ID,
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL,
  },
   applicationScope: {
    scopes: [
      process.env.REACT_APP_MSAL_APPLICATION_SCOPE
    ]
  },
  extras: {
    consentScopes: [
      'user.read'
    ],
    extraQueryParameters: {
      lang: 'en'
    }
  },
  userScope: {
    scopes: [
      'user.read'
    ]
  },
  guardConfiguration: {
    authRequest: {
      scopes: [
        'user.read'
      ]
    },
    loginFailedRoute: '/login'
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  }
  };
  
export const msalInstance = new PublicClientApplication(msalConfig);
