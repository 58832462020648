import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchDivisions,fetchSystems } from "../features/dashboard/dashboardSlice";
import { fetchAllData,fetchAllDataBrasil } from "../features/users/formDataSlice";
import { AppDispatch } from '../store';

export const useDataFetch = (isBrazilUser=0) => {
    const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchDivisions());
   

    if(isBrazilUser===0)
    dispatch(fetchAllData());
    else {
      dispatch(fetchSystems());
     dispatch(fetchAllDataBrasil());
    }
  }, [dispatch,isBrazilUser]);
};
