import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {  registerUser, selectUserLoading } from '../features/users/userSlice';
import InputField from '../components/InputField';
import SelectField from '../components/SelectField';
import { AppDispatch } from '../store';
import { useGraphToken} from '../hooks/useGraphToken'
import {getRoles} from "../constants/roles"
import { showNotificationWithTimeout } from '../utils/common';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required').max(50, 'Max length is 50'),
  lastName: Yup.string().required('Last Name is required').max(50, 'Max length is 50'),
  email: Yup.string().email('Invalid email').required('Email is required').max(100, 'Max length is 100')
});

const AddUser: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const roles = getRoles('DISPATCHER');
  const loading = useSelector(selectUserLoading);
  const {generateGraphToken} = useGraphToken();

  return (
    <div className="container-fluid">
       {loading === true && (
        <div className="overlay">
          <div className="loading-icon"></div>
        </div>
      )}
      <div className="row-fluid">
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            roleId: 2,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            values.roleId=2;
            try {
              const graphToken = await generateGraphToken();
              await dispatch(registerUser({
                userData: values,
                headers: {
                  'Graphtoken': graphToken,
                },
              })).unwrap();
              showNotificationWithTimeout(dispatch, 'User registered successfully', 'success');
              resetForm();
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {() => (
            <Form className="form-signin1 mg-tb50 form-horizontal wd800">
              <h3 className="line-btm mg-top10">New External User Setup</h3>
              <div className="form-group mg-btm0 mg-top15">
                <InputField label="First Name:" name="firstName" className="col-sm-8" labelClassName="col-sm-3" />
              </div>
              <div className="form-group mg-btm0 mg-top15">
                <InputField label="Last Name:" name="lastName" className="col-sm-8" labelClassName="col-sm-3" />
              </div>
              <div className="form-group mg-btm0 mg-top15">
                <InputField label="Email ID:" name="email" type="email" className="col-sm-8" labelClassName="col-sm-3" />
              </div>
              <div className="form-group mg-btm0 mg-top15">
                <SelectField label="Roles:" name="roleId" className="col-sm-8"  labelClassName="col-sm-3" skipChoose={true} options={roles.map(role => ({ value: role.id, label: role.label }))} />
              </div>
              <div className="form-group mg-btm0 mg-top15">
                <div className="col-sm-11 txt-right">
                  <button type="reset" className="btn icn-refresh">Reset</button>&nbsp;
                  <button type="submit" className="btn icn-submit1" disabled={loading}>Submit</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddUser;
