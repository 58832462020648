import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardData1 } from '../../features/dashboard/dashboardSlice';
import { RootState, AppDispatch } from '../../store';
import GenericChartComponent from '../../components/GenericChartComponent';
import Loading from "../../components/Loading"

const StatusChart = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { data1,status } = useSelector((state: RootState) => state.dashboard);

  useEffect(() => {
    dispatch(fetchDashboardData1());
  }, [dispatch]);

  const chartData = useMemo(() => {
    const validData = Array.isArray(data1) ? data1 : [];
    return {
      labels: validData.map((item) => item.State),
      datasets: [{
        data: validData.map((item) => item.Count),
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(153, 102, 255)',
        ],
      }],
    };
  }, [data1]);

  const chartOptions = useMemo(() => ({
    responsive: true
  }), []);

  return (
    <div>
      <h2>Treq's based on Status</h2>
      <div id="canvas-holder" style={{ float: 'left', width: '55%', marginLeft: '25%' }}>
      {status==='loading'?<Loading style={{height:'40vh',width:'100%'}} />:
          <GenericChartComponent type="pie" data={chartData} options={chartOptions} />
          }
      </div>
    </div>
  );
};

export default StatusChart;