import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import DatePickerField from './DatePickerField';
import InputField from './InputField';

interface TreqFormProps {
    fetchData: (params: any) => void;
    setFilterParams: (startDate: string, endDate: string, treqId: string) => void;
}

const TreqForm: React.FC<TreqFormProps> = ({ fetchData, setFilterParams }) => {

    const initialValues = {
        startDate: '',
        endDate: '',
        treqId: '',
        fromScheduleLink: false
    };

    const handleSubmit = (values: { startDate: string; endDate: string; treqId: string; fromScheduleLink: boolean }) => {
        const { startDate, endDate, treqId } = values;
        setFilterParams(startDate, endDate, treqId);
    };

    const validationSchema = Yup.object({
        startDate: Yup.date()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr),
        endDate: Yup.date()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .min(Yup.ref('startDate'), 'End date must be later than start date'),
        treqId: Yup.string()
    });

    return (
        <div className="col-md-12 form-horizontal treq-table">
            <div className="form-group">
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    <Form className="form-horizontal">
                        <div className="form-group">
                            <DatePickerField label="From:" name="startDate" className="col-sm-2 mgleft20" labelClassName='mrright18' labelIn={true} />
                            <DatePickerField label="To:" name="endDate" className="col-sm-2" labelClassName='mrright18' labelIn={true} />
                            <InputField label="T-Req ID:" name="treqId" type="text" className="col-sm-2" labelClassName='fleft' />
                            <div className="col-sm-2">
                                <button className="btn icn-search" type="submit" id="btnSearch">Search</button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default TreqForm;