import axiosInstance,{handleAxiosError} from './axiosInstance';
import { ENDPOINTS } from '../api/config';
export const submitFormData = async (data: any) => {
  try {
    const response = await axiosInstance.post(ENDPOINTS.SAVE_TREQ, data);
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

export const submitAttachmentData = async (id: string, formData: FormData) => {
  try {
    const response = await axiosInstance.post(`${ENDPOINTS.SAVE_ATTACHMENT}?treqId=${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};