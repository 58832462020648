import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import InputField from './InputField';
import SelectField from './SelectField';
import TextAreaField from './TextAreaField';
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { useFormikContext } from 'formik';
import {getValueById,getYear} from "../utils/common"

interface TransportationProps {
  readOnly?: boolean; 
}

const TransportationInfo: React.FC<TransportationProps> = ({ readOnly = false }) => {
  const operationType = [{ id: "Onshore" }, { id: "Offshore" }];
  const { transportList, equipmentList } = useSelector((state: RootState) => state.formData);
  const { values, setFieldValue } = useFormikContext<any>();
  const { divisions } = useSelector((state: RootState) => state.dashboard);



  const getTransportNameById =  useCallback((id: string) => {
    const transport = transportList.find(v => v.id === parseInt(id));
    return transport ? transport.name : id;
  }, [transportList]);
  const [urbanTransportValues, setUrbanTransportValues] = useState<string[]>([]);
  const [longDistTransportValues, setLongDistTransportValues] = useState<string[]>([]);

  const prevUrbanTransportValues = useRef(values.urbanTransportationId);
  const prevLongDistTransportValues = useRef(values.longDistTransportationId);

  useEffect(() => {
    setUrbanTransportValues(values.urbanTransportationId);
    setLongDistTransportValues(values.longDistTransportationId);
    values?.transportQuantityData.forEach((item : any)=>{
      if(item.type){
        setFieldValue(`urbanTransportValues.${item.transportationId}`,item.Quantity)
        setFieldValue(`urbanTransportValues.description${item.transportationId}`,item.description)
      }
      else {
        setFieldValue(`longDistTransportValues.${item.transportationId}`,item.Quantity)
        setFieldValue(`longDistTransportValues.description${item.transportationId}`,item.description)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (JSON.stringify(prevUrbanTransportValues.current) !== JSON.stringify(values.urbanTransportationId)) {
      setUrbanTransportValues(values.urbanTransportationId || []);
      prevUrbanTransportValues.current = values.urbanTransportationId;
    }

    if (JSON.stringify(prevLongDistTransportValues.current) !== JSON.stringify(values.longDistTransportationId)) {
      setLongDistTransportValues(values.longDistTransportationId || []);
      prevLongDistTransportValues.current = values.longDistTransportationId;
    }
  }, [values.urbanTransportationId, values.longDistTransportationId]);

  const urbanTransportData = useMemo(() => 
    urbanTransportValues
    .filter(val=>val)
    .map(val => ({
      transportationId: val,
      quantity: values.urbanTransportValues?.[val] || '',
      description: getTransportNameById(val).includes('Outro') ? values.urbanTransportValues?.[`description${val}`] || '' : ''
    }))
  , [urbanTransportValues, values.urbanTransportValues,getTransportNameById]);

  const longDistTransportData = useMemo(() => 
    longDistTransportValues
    .filter(val=>val)
    .map(val => ({
      transportationId: val,
      quantity: values.longDistTransportValues?.[val] || '',
      description: getTransportNameById(val).includes('Outro') ? values.longDistTransportValues?.[`description${val}`] || '' : ''
    }))
  , [longDistTransportValues, values.longDistTransportValues,getTransportNameById]);

  useEffect(() => {
    setFieldValue('selectedVal', urbanTransportData);
    setFieldValue('ltdSelectedVal', longDistTransportData);
  }, [urbanTransportData, longDistTransportData, setFieldValue]);

  return (
    <div className="col-md-12 form-horizontal white content-border padding15" id="divTransportationInfo">
      <fieldset>
        <legend>Transportation Information (For SCM - Logistics Team)</legend>
        <div className="form-group">
          <label className="control-label col-sm-1 fw-norm p-lr5">Transportation Request Number/Code:</label>
          <div className="col-md-2">
            <label className="control-label fw-norm p-lr5 bld-txt" id="TreqCodeID">{values.requestid?`${values.requestid}-${getValueById(divisions,values.divisionCode,"divisionCode","description")}-${getYear(values.dateCreated)}`:null}</label>
          </div>
          <SelectField readOnly={readOnly} label="Cargo Movement Equipment" name="corgoMoveEquiId" options={equipmentList} valueField="id" labelField="name" className="col-md-2" labelClassName="col-sm-1" />
          <SelectField readOnly={readOnly} label="Operations Type" name="operationsType" options={operationType} valueField="id" labelField="id" className="col-md-2" labelClassName="col-sm-1" />
          <InputField readOnly={readOnly} label="Transportation Company" name="nameOfPULocationContact" className="col-md-2" labelClassName="col-sm-1" />
        </div>
        <div className="form-group">
          <InputField readOnly={readOnly} label="Voucher" name="voucher" className="col-md-2" labelClassName="col-sm-1" />
          <InputField readOnly={readOnly} label="Value" name="transportationValue" decimal className="col-md-2" labelClassName="col-sm-1" />
          <InputField readOnly={readOnly} label="COPQ" name="COPQ" decimal className="col-md-2" labelClassName="col-sm-1" />
        </div>
        <div className="form-group">
          <TextAreaField readOnly={readOnly} label="Notes" name="notes" rows={5} maxlength={1000} />
        </div>
        <div className="form-group">
          <div className="col-md-6">
            <SelectField
              readOnly={readOnly}
              label="Urban Transport (Macaé / Rio das Ostras)"
              name="urbanTransportationId"
              options={transportList}
              className="col-md-4"
              labelClassName="col-sm-2"
              valueField="id"
              labelField="name"
              multiple={true}
            />
            <div style={{ marginTop: 12 }} className='col-md-12'>
              {urbanTransportValues.map((val, idx) => (
                val!==""&&
                <div key={idx + val + 'tra'} className="col-md-12 form-group">
                  <label className="control-label col-sm-2">{getTransportNameById(val)}</label>
                  <div className="col-sm-9">
                    <InputField readOnly={readOnly} name={`urbanTransportValues.${val}`} label="" numeric className="col-md-4" />
                    {getTransportNameById(val).includes('Outro') && <InputField readOnly={readOnly} name={`urbanTransportValues.description${val}`} label="Description" className="col-md-3" labelClassName='col-md-2' />}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6">
            <SelectField
              label="Transport long distance"
              name="longDistTransportationId"
              options={transportList}
              className="col-md-4"
              labelClassName="col-sm-2"
              valueField="id"
              labelField="name"
              readOnly={readOnly}
              multiple={true}
            />
            <div style={{ marginTop: 12 }} className='col-md-12'>
              {longDistTransportValues.map((val, idx) => (
                 val!==""&&
                <div key={idx+'lng'+val} className="col-md-12 form-group">
                  <label className="control-label col-sm-2">{getTransportNameById(val)}</label>
                  <div className="col-sm-9">
                    <InputField name={`longDistTransportValues.${val}`} readOnly={readOnly} label="" numeric className="col-md-4" />
                    {getTransportNameById(val).includes('Outro') && <InputField readOnly={readOnly} name={`longDistTransportValues.description${val}`} label="Description" className="col-md-3" labelClassName='col-md-2' />}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default TransportationInfo;
