import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import apiStatusReducer from '../reducers/apiStatusReducer';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import userReducer from '../features/users/userSlice';
import groupReducer from '../features/groups/groupSlice';
import formDataReducer from '../features/users/formDataSlice';
import treqReducer from '../features/treq/treqSlice';
import oldTreqReducer from '../features/treq/oldTreqSlice';
import notificationReducer from '../features/notification/notificationSlice';
import formSubmissionReducer from '../features/formSubmissionSlice';
import modalReducer from '../features/modal/modalSlice';

export const store = configureStore({
  reducer: {
    notification: notificationReducer,
    auth: authReducer,
    user: userReducer,
    dashboard: dashboardReducer,
    apiStatus: apiStatusReducer,
    formData:formDataReducer,
    treq: treqReducer,
    formSubmission: formSubmissionReducer,
    oldTreq: oldTreqReducer,
    groups: groupReducer,
    modal:modalReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
