import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GeneralInformationDetail from "../components/GeneralInformationDetail";
import DriverTravelInformationDetail from "../components/DriverTravelInformationDetail";
import FlightInformationDetail from "../components/FlightInformationDetail";
import VehicleInformationDetail from "../components/VehicleInformationDetail";
import TravelInformationDetail from "../components/TravelInformationDetail";
import axiosInstance from "../api/axiosInstance";
import { ENDPOINTS } from "../api/config";
import Loading from "../components/Loading";

import { useDataFetch } from "../hooks/useDataFetch";

const DetailTreq: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [apiData, setApiData] = useState({} as any);
  useDataFetch();
  useEffect(() => {
    const fetchTreqData = async () => {
      try {
        const response = await axiosInstance.get(
          `${ENDPOINTS.GET_OLD_TREQ_DATA_DETAIL}${id}&fromScheduleLink=false`
        );
        const data = response.data;
        setApiData(data);
      } catch (error) {
        console.error("Error fetching T-Req data", error);
      }
    };

    fetchTreqData();
  }, [id]);

  if (!apiData.generalData) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div className="page-content overflow">
      <h3 className="page-title">Old T-Req Details - {id}</h3>
      <hr className="hr" />
      <div className="form-horizontal white content-border padding15 padding-btm5">
        <div className="row">
          <div className="col-md-9">
            <GeneralInformationDetail data={apiData?.generalData} />
          </div>
          <FlightInformationDetail data={apiData?.flightData} />
        </div>
        <div className="row">
          <div className="col-md-4">
            <TravelInformationDetail data={apiData?.destinationData} />
          </div>
          <div className="col-md-4">
            <VehicleInformationDetail data={apiData?.vehicleData} />
          </div>
          <div className="col-md-4">
            <DriverTravelInformationDetail data={apiData?.driverTravelData} />
          </div>
        </div>
        <div className="row">
        <div className="col-md-12 form-horizontal white ">
                <fieldset>
                    <legend>Purpose of Trip / Comments:</legend>
                    <div className="form-group">                        
                        <div className="col-md-10" id="divPurpose">{apiData?.generalData?.purposeoftrip}</div>

                    </div>
                </fieldset>
            </div>
            </div>
      </div>
    </div>
  );
};

export default DetailTreq;
