import { showNotification, hideNotification } from '../features/notification/notificationSlice';
import { Dispatch } from 'redux';
import { ROLES, ROLE_IDS } from '../constants/roles';
import _ from 'lodash';
const timeToRemoveNotification = 3000;



export const tranformData = (
  data: any[],
  type: string,
  searchKeyword = "type",
  fieldForValue = "description",
  fieldForId = "udc"
) => {
  return data
    .filter((element) => element[searchKeyword] === type)
    .map((element) => ({
      id: element[fieldForId],
      value: element[fieldForValue],
    }));
};

export const getValueById = (
  array,
  id,
  idFieldName,
  valueFieldName
) => {
 // eslint-disable-next-line
  const item = array.find((item)=>item[idFieldName]==id);
  return item?item[valueFieldName]:null
};


export const combineDateTime = (date: Date, time: Date) => {
  if (!date || !time) return null;

  const combined = new Date(date);
  combined.setHours(time.getHours());
  combined.setMinutes(time.getMinutes());
  combined.setSeconds(0);
  combined.setMilliseconds(0);

  return combined;
};

export const formatDateTime = (dateTime: Date) => {
  if (!dateTime) return '';
  const formattedDate = formatDate(dateTime);
  const formattedTime = formatTime(dateTime);

  return `${formattedDate} ${formattedTime}`;
};

export const formatDate = (dateToFormat: Date) => {
  if (!dateToFormat) return '';

  const date = new Date(dateToFormat);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  return `${formattedDate}`;
};

export const formatTime = (timeToFormat: Date) => {
  if (!timeToFormat) return '';

  const date = new Date(timeToFormat);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}`;

  return `${formattedTime}`;
};

export const formatDateAPI = (date: Date) => {
  if (!date) return '';
  const d = new Date(date);
  const month = (`0${d.getMonth() + 1}`).slice(-2); // Add leading zero if needed
  const day = (`0${d.getDate()}`).slice(-2); // Add leading zero if needed
  const year = d.getFullYear();
  return `${month}-${day}-${year}`;
};


export const getYear = (date: Date) => {
  if (!date) return '';
  const d = new Date(date);
  const year = d.getFullYear();
  return `${year}`;
};

export const showNotificationWithTimeout = (dispatch: Dispatch, message: string, type: 'success' | 'error' | 'loading') => {
  dispatch(showNotification({ message, type }));
  setTimeout(() => {
    dispatch(hideNotification());
  }, timeToRemoveNotification);
};


export const getChangedValues = (apiData, payload) => {
  const changedValues = {};

  const compareObjects = (original, current, basePath = '') => {
    Object.keys(current).forEach(key => {
      const path = basePath ? `${basePath}.${key}` : key;
      if (original[key] && typeof original[key] === 'object' && typeof current[key] === 'object') {
        compareObjects(original[key], current[key], path);
      } else if (original[key] !== current[key]) {
        _.set(changedValues, path, current[key]);
      }
    });
  };

  compareObjects(apiData, payload);
  return changedValues;
};


export const findChangedValues = (apiData, payload, selectFieldOptions) => {
  const changedDetails = {};
  const mapfields={
    activity:"activity",
    airlineCode:"airlinecode",
    consolidationsId:"consolidationsId",
    deliveryCityId:"deliveryCity",
    deleiveryLocationId:"deliveryLocation",
    deliveryStateId:"deliveryState",
    divisionCode:"divisionCode",
    driverid:"driverId",
    endPointId:"endPointId",
    puCityId:"pickupCity",
    locationId:"pickupLocation",
    puStateId:"pickupState",
    startingPointId:"startingPointId",
    trailerid:"trailerid",
    vehicleid:"vehicleId",
    operationsType:"operationsType",
    urbanTransportationId:"urbanTransportationId",
    longDistTransportationId:"longDistTransportationId",
    corgoMoveEquiId:"corgoMoveEquiId",
  };

  const isEqual = (value1, value2) => {
    // eslint-disable-next-line eqeqeq
    if (value1 == value2) return true;
    if (value1 instanceof Date && value2 instanceof Date) return value1.getTime() === value2.getTime();
    if ((value1 === null && value2 === "") || (value1 === "" && value2 === null)) return true;
    if ((value1 === 0 && value2 === false) || (value1 === false && value2 === 0)) return true;
    if ((value1 === 1 && value2 === true) || (value1 === true && value2 === 1)) return true;
    return false;
  };

  const getOptionText = (field, value) => {
    const options = selectFieldOptions[field];
    let valuesArray;
    if (Array.isArray(value)) {
        valuesArray = value;
    } else if (typeof value === 'string' && value.includes(',')) {
        valuesArray = value.split(',');
    } else {
      
        valuesArray = [value];
    }
    const labelsArray = valuesArray
      .filter(Boolean)
      .map(singleValue => {
        singleValue = String(singleValue);
        const foundOption = options?.find(option => option.value === singleValue.trim());
        return foundOption ? foundOption.label : singleValue;
    });
    return labelsArray.join(', ');
};

  const checkChanges = (oldObj, newObj, resultObj) => {
    Object.keys(newObj).forEach(key => {
      if (typeof newObj[key] === 'object' && newObj[key] !== null && typeof oldObj[key] === 'object' && oldObj[key] !== null) {
        const nestedChanges = {};
        checkChanges(oldObj[key], newObj[key], nestedChanges);
        if (Object.keys(nestedChanges).length > 0) {
          resultObj[key] = nestedChanges;
        }
      } else if (!isEqual(oldObj[key], newObj[key]) && key!=="type" && key!=="treqId" && key!=="dispatcherid" && key !=="removeFileAttachments" && key !=="ldtSelectedVal" && key !=="selectedVal" )  {
      
        const keyToSelect=mapfields[key]?mapfields[key]:key;

        resultObj[key] = {
          oldText: selectFieldOptions[keyToSelect] ? getOptionText(keyToSelect, oldObj[key]) : oldObj[key],
          newText: selectFieldOptions[keyToSelect] ? getOptionText(keyToSelect, newObj[key]) : newObj[key],
          isSelect:selectFieldOptions[keyToSelect]?true:false
        };
      }
    });
  };

  checkChanges(apiData, payload, changedDetails);
  return changedDetails;
};


export const getRoleIdByRoleName = (roleName: string): number | undefined => {
  const roleKey = Object.keys(ROLES).find(key => ROLES[key as keyof typeof ROLES] === roleName);
  return roleKey ? ROLE_IDS[roleKey as keyof typeof ROLE_IDS] : undefined;
};


export function formatDateUTC(isoDateString: string) {
  if (!isoDateString) return '';
  const date = new Date(isoDateString);

  // Extract components using UTC methods
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // getUTCMonth returns 0-11
  const day = date.getUTCDate();


  const formattedMonth = month.toString().padStart(2, '0');
  const formattedDay = day.toString().padStart(2, '0');


  // Combine components into final strings
  const formattedDate = `${formattedMonth}-${formattedDay}-${year}`;
  

  return formattedDate;
}
export function formatTimeUTC(isoDateString: string) {
  if (!isoDateString) return '';
  const date = new Date(isoDateString);

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();


  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  const formattedTime = `${formattedHours}:${formattedMinutes}`;
  

  return formattedTime;
}
