import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDataFetch } from "../hooks/useDataFetch";
import { useDispatch, useSelector } from "react-redux";
import { getValidationSchema } from "../components/CreateTreq/formConfig";
import GeneralInformation from "../components/GeneralInformation";
import TransportationInfo from "../components/TransportationInfo";
import FlightInformation from "../components/FlightInformation";
import VehicleInformation from "../components/VehicleInformation";
import TravelInformation from "../components/TravelInformation";
import Attachment from "../components/Attachment";
import InputField from "../components/InputField";
import { submitForm, submitAttachments } from "../features/formSubmissionSlice";
import { RootState } from "../store";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance, { updateBaseUrl } from "../api/axiosInstance";
import { ENDPOINTS } from "../api/config";
import Loading from "../components/Loading";
import { useAuth } from "../utils/auth";
import { findChangedValues,formatTimeUTC ,formatDateUTC} from "../utils/common";
import CommonModal from "../components/CommonModal";
import {useBlockNavigation} from "../hooks/useBlockNavigation"

import { createPayload } from "../models/createPayload";
import { ROLES } from "../constants/roles";

const EditTreq: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const formSubmission = useSelector(
    (state: RootState) => state.formSubmission
  );
  const navigate = useNavigate();

  const [actionType, setActionType] = useState(2); // Default to 2 for submit
  const [initialValues, setInitialValues] = useState<any>(null);
  const { user } = useAuth();
  const isBrazilUser = user?.isBrazilUser;
  const userRole = user?.role;
  const [forceSubmit, setForceSubmit] = useState(false);
  const [showAlternateSubmit, setShowAlternateSubmit] = useState(false);
  const [isFormDirty, setIsFormDirty]=useState(false);
  const [countdown, setCountdown] = useState(10);
  const [showTimer, setShowTimer] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const [apiData, setApiData] = useState({} as any);

  useEffect(() => {
    let timer;
    if (showTimer && countdown > 0) {
      timer = setInterval(() => {
        setModalContent(<StatusMessage />);
        setCountdown(prevCountdown => prevCountdown - 1);
        
      }, 1000);
    } else if (countdown === 0) {
     window.close();
    }
    return () => clearInterval(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTimer, countdown]);

  const handleFormChange = () => {
    setIsFormDirty(true);
  };

  const StatusMessage = () => {
    let messageType = "";
    switch (actionType) {
        case 1:
        case 5:
            messageType = "saved";
            break;
        case 2:
            messageType = "submitted";
            break;
        case 3:
            messageType = "accepted";
            break;
        case 4:
            messageType = "closed";
            break;
        case 6:
            messageType = "confirmed";
            break;
        default:
            break;
    }

    return (
        <p className='status-message'>
            {`Treq ${messageType} successfully!`}
            {user?.SchedulinkInfo && <span>This page will be closed after {countdown} seconds.</span>}
        </p>
    );
};



  useBlockNavigation(isFormDirty,"You have unsaved changes. Are you sure you want to leave?");

  useDataFetch(isBrazilUser);

  const recommendedFields = isBrazilUser
    ? ["dateNeeded", "timeNeeded", "projectManager", "purposeoftrip"]
    : [
        "passengers",
        "parcels",
        "dateNeeded",
        "timeNeeded",
        "projectManager",
        "purposeoftrip",
        "pickupState",
        "pickupCity",
        "pickupLocation",
        "deliveryState",
        "deliveryCity",
        "pickupContactName",
        "contactTelephone",
      ];
  const fieldNamesMap = {
    passengers: "No. Of Passengers",
    parcels: "No. Of Parcels",
    dateNeeded: "Date Needed",
    timeNeeded: "Time Needed",
    purposeoftrip: "Purpose of T-REQ",
    projectManager: "project manager",
    pickupState: "P/U State",
    pickupCity: "P/U City",
    pickupLocation: "P/U Location",
    deliveryState: "Delivery State",
    deliveryCity: "Delivery City",
    pickupContactName: "Name of Contact P/U Location",
    contactTelephone: "Contact Telephone #",
  };
  const checkRecommendedFields = (values: any) =>
    recommendedFields.filter((field) => !values[field]);



  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to leave?";
      }
    };
  
    const handleNavigation = (event: Event) => {
      if (isFormDirty && !window.confirm("You have unsaved changes. Are you sure you want to leave?")) {
        event.preventDefault();
      }
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handleNavigation);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleNavigation);
    };
  }, [isFormDirty]);

  const handleModalClose = () => {
    setModalVisible(false);
    setShowAlternateSubmit(false);
    if (!isError) {
      if (user?.SchedulinkInfo) {
        window.close();
      } else {
        window.location.href = "/treq-listing";
      }
    }
  };

  const handleForceSubmit = async () => {
    setForceSubmit(true);
    setModalVisible(false);
    document
      .getElementById(
        actionType === 1 ? "force-save-button" : "force-submit-button"
      )
      ?.click();
  };

  const openConfirmModal = () => {
    setModalHeader("Confirmation Message");
    setModalContent(
      <>
        <p>
          Are you sure, you want to upload this T-Req to logistic Code & Pay?
        </p>

        <div className="modal-footer">
          <button type="button" onClick={openFormModal} className="btn icn-ok">
            OK
          </button>
          <button
            type="button"
            onClick={handleModalClose}
            className="btn icn-cancel"
            data-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </>
    );
    setIsError(true);
    setModalVisible(true);
  };

  const openFormModal = () => {
    setModalHeader("Upload to Logistic Code & Pay");
    setModalContent(
      <Formik
        initialValues={{ amount: "", invoiceNumber: "" }}
        validationSchema={Yup.object({
          amount: Yup.number().required("Amount is required"),
          invoiceNumber: Yup.string().required("Invoice number is required"),
        })}
        onSubmit={handleCodePaySubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            {isSubmitting && <div className="overlay">
          <div className="loading-icon"></div>
        </div>}
            <div className="form-group" style={{ display: "flow-root" }}>
              <div>
                <InputField
                  label="Amount:"
                  name="amount"
                  labelClassName="col-sm-3"
                  className="col-sm-9"
                  numeric
                />
              </div>
            </div>
            <div className="form-group">
              <div>
                <InputField
                  label="Invoice Number:"
                  name="invoiceNumber"
                  labelClassName="col-sm-3"
                  className="col-sm-9"
                />
              </div>
              <div className="right mg-top15">
                <button type="submit" className="btn icn-save" id="btnLogSave">
                  {isSubmitting?'Saving':'Continue'}
                </button>
                &nbsp;
                <button
                  type="button"
                  className="btn icn-cancel"
                  onClick={handleModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
    setModalVisible(true);
  };

  const handleCodePaySubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const { amount, invoiceNumber } = values;
      updateBaseUrl(0);
      await axiosInstance.post(ENDPOINTS.UPLOAD_CODE_PAY, {
        amount,
        invoice: invoiceNumber,
        fromScheduleLink: false,
        treqId: id,
      });
      setIsError(false);
      setModalContent("Upload and Scan pay done successfully");
    } catch (error) {
      setIsError(true);
      setModalContent("Failed to upload to Code & Pay: " + error);
    } finally {
      setSubmitting(false);
    }
  };

  const handlePrint = async () => {
    try {
      updateBaseUrl(0);
      const response = await axiosInstance.post(
        `${ENDPOINTS.GET_LIST_EXPORT}`,
        [apiData.generalData?.requestid],
        { responseType: "blob" }
      );
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", "Transportation_Request_Print.pdf");

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setModalHeader("");
      setModalContent("Failed to download PDF: " + error);
      setIsError(true);
      setModalVisible(true);
    }
  };

  const handleReopen = async () => {
    if (window.confirm("Are you sure you want to re-open this T-Req?")) {
      try {
        updateBaseUrl(0);
        await axiosInstance.get(`${ENDPOINTS.REOPEN_TREQ}?treqId=${apiData.generalData?.requestid}`);
        setModalHeader("");
        setModalContent("T-Req reopened successfully!");
        setIsError(false);
        setModalVisible(true);
      } catch (error) {
        setModalHeader("");
        setModalContent("Error reopening T-Req. Please try again.");
        setIsError(true);
        setModalVisible(true);
      }
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this T-Req?")) {
      try {
        updateBaseUrl(0);
        await axiosInstance.delete(`${ENDPOINTS.DELETE_TREQ}?treqId=${id}`);
        setModalContent("T-Req deleted successfully!");
        setIsError(false);
        setModalVisible(true);
      } catch (error) {
        setModalContent("Error deleting T-Req. Please try again.");
        setIsError(true);
        setModalVisible(true);
      }
    }
  };

  const handleClone = () => {
    const cloneData = { ...initialValues };
    console.log(cloneData);
    delete cloneData.requestid;
    delete cloneData.attachments;
    navigate("/create-treq", { state: { initialValues: cloneData } });
  };

  useEffect(() => {
    // Fetch existing data
    const fetchTreqData = async () => {
      try {
        updateBaseUrl(0);

       const stringToQuery=user?.SchedulinkInfo?`&fromScheduleLink=true&assignmentId=${user?.SchedulinkInfo.assignmentId}&type=${user?.SchedulinkInfo.type}`:'&fromScheduleLink=false';
        
        
        const response = await axiosInstance.get(
          `${ENDPOINTS.GET_TREQ_DATA_EDIT}${id}${stringToQuery}`
        );
        
       

          const data = response.data;
        updateBaseUrl(0);
        const attachments = await axiosInstance.get(
          `${ENDPOINTS.GET_ATTACHMENTS}?treqId=${data.generalData.requestid}`
        );
        setApiData(data);
        const mappedInitialValues = {
          requestid: data.generalData?.requestid,
          requestor: data.generalData?.requestor,
          dateCreated: data.generalData?.dateCreated,
          requestorid: data.generalData?.requestorid,
          projectId: data.generalData?.project,
          projectName: data.generalData?.projectName,
          projectDescription: data.generalData?.projectDescription,
          dateNeeded: formatDateUTC(data.generalData?.dateNeeded),
          timeNeeded: data.generalData?.timeNeeded,
          activity: data.generalData?.activity,
          projectManager: data.generalData?.projectMgr,
          telephone: data.generalData?.telephoneNumber,
          divisionCode: data.generalData?.divisionCode,
          purposeoftrip: data.generalData?.purposeoftrip,
          passengers: data.generalData?.numberOfPassengers,
          parcels: data.generalData?.numberOfParcels,
          backhaul: data.generalData?.backhaul,
          // Vehicle data
          vehicleId: data.vehicleData?.vehicleid,
          driverId: data.vehicleData?.driverid,
          trailerid: data.vehicleData?.trailerid,
          consolidationsId: data.vehicleData?.consolidationsId,
          numberOfConsolidations: data.vehicleData?.numberOfConsolidations,
          finalMiles: data.vehicleData?.finalMiles,
          startingPointId: data.vehicleData?.startingPointId,
          endPointId: data.vehicleData?.endPointId,
          mileageAtPU: data.vehicleData?.mileageAtPU,
          timeAtPU: formatTimeUTC(data.vehicleData?.timeAtPU),
          waitTimeAtPU: data.vehicleData?.waitTimeAtPU,
          mileageAtDeliveryLocation:
            data.vehicleData?.mileageAtDeliveryLocation,
          timeAtDeliveryLocation: formatTimeUTC(data.vehicleData?.timeAtDeliveryLocation),
          vehicleInspectionComments:
            data.vehicleData?.vehicleInspectionComments,
          problems: data.vehicleData?.problems,
          startingMileage: data.vehicleData?.beginningMiles,
          endingMiles: data.vehicleData?.endingMiles,
          startTime: formatTimeUTC(data.vehicleData?.startTime),
          endTime: formatTimeUTC(data.vehicleData?.endTime),
          waitTimeAtDelivery: data.vehicleData?.waitTimeAtDelivery,
          otherDriver: data.vehicleData?.otherDriver,
          dispatcherName: data.vehicleData?.dispatcherName,
          // Flight data
          airlinecode: data.flightData?.airlineCode,
          fltnumber: data.flightData?.flightNumber,
          FlightFrom: data.flightData?.flightFrom,
          FlightTo: data.flightData?.flightTo,
          etaDate: formatDateUTC(data.flightData?.flightArrivaltime),
          etdTime: formatTimeUTC(data.flightData?.flightDeparturetime),
          // Travel data
          pickupState: data.travelData?.puStateId,
          pickupCity: data.travelData?.puCityId,
          pickupLocation: data.travelData?.locationId,
          deliveryState: data.travelData?.deliveryStateId,
          deliveryCity: data.travelData?.deliveryCityId,
          deliveryLocation: data.travelData?.deleiveryLocationId,
          pickupContactName: data.travelData?.nameOfPULocationContact,
          contactTelephone: data.travelData?.telephoneOfPULocationContact,
          otherPickupCity: data.travelData?.otherPUCity,
          otherDeliveryCity: data.travelData?.otherDeliveryCity,
          otherPickupLocation: data.travelData?.otherLocation,
          otherDeliveryLocation: data.travelData?.otherDeliveryLocation,
          //Transportation Data
          voucher: data.transportationData?.voucher,
          transportationValue: data.transportationData?.transportationValue,
          operationsType: data.transportationData?.operationsType,
          corgoMoveEquiId: data.transportationData?.moveEquipmentId,
          location: data.transportationData?.location,
          notes: data.transportationData?.notes,
          COPQ: data.transportationData?.COPQ,
          origin: data.transportationData?.origin,
          destination: data.transportationData?.destination,
          urbanTransportationId:
            data.transportationData?.urbanTransportationId.split(",") || [],
          urbanTransportDesc: data.transportationData?.urbanTransportDesc,
          longDistDesc: data.transportationData?.longDistDesc,
          createdDate: data.transportationData?.createdDate,
          attachments: attachments.data,
          nameOfPULocationContact:
            data.transportationData?.nameOfPULocationContact,
          longDistTransportationId:
            data.transportationData?.longDistTransportationId.split(",") || [],
          transportQuantityData: data?.transportQuantityData,
        };

        setInitialValues(mappedInitialValues);
      } catch (error) {
        console.error("Error fetching T-Req data", error);
      }
    };

    fetchTreqData();
  }, [id,user?.SchedulinkInfo]);

  const onSubmit = (values: any, actions: any) => {
    setIsFormDirty(false);
    const emptyRecommendedFields = checkRecommendedFields(values);
    if (emptyRecommendedFields.length > 0 && !forceSubmit && actionType <= 2) {
      setShowAlternateSubmit(true);
      setModalHeader("It is recommended to fill the below information:");
      setModalContent(
        <>
          <ul>
            {emptyRecommendedFields.map((field, index) => (
              <li key={index}>{fieldNamesMap[field]}</li>
            ))}
          </ul>
          <br />
          Do you really want to continue?
        </>
      );
      setIsError(true);
      setModalVisible(true);
      return;
    }
    setShowAlternateSubmit(false);
    setForceSubmit(true);
    const payload = createPayload(values, actionType, isBrazilUser, values.requestid);
     if (user?.SchedulinkInfo){
      payload.SchedulinkInfo=user?.SchedulinkInfo;
    }
    
    if (actionType === 5 || actionType === 6) {
      const selectFieldOptions = {};
    document.querySelectorAll("select").forEach((selectElement) => {
      const name = selectElement.name;
      const options = Array.from(selectElement.options)
      .filter((option) => option.value !== '' && option.value !== null)
      .map((option) => ({
        value: option.value,
        label: option.text,
      }));

      selectFieldOptions[name] = options;
    });

      const changedValues = findChangedValues(apiData, payload,selectFieldOptions);
      if((values.attachments?.add && values.attachments?.add.length > 0) || (values.attachments?.remove && values.attachments?.remove.length > 0))
        changedValues.IsAttachmentChanged=true;
      else
        changedValues.IsAttachmentChanged=false;
      delete changedValues.removeFileAttachments;
      payload.changedFields = changedValues;
    }


    dispatch(submitForm(payload))
      .unwrap()
      .then((response) => {
        const treqId = response.requestId;
        const formData = new FormData();
        (values.attachments?.add || []).forEach((fileData: any) => {
          formData.append("files", fileData.file, fileData.file.name);
        });


        if (user?.SchedulinkInfo){
          setShowTimer(true)
        }

      

        if (values.attachments?.add && values.attachments?.add.length > 0) {
          dispatch(submitAttachments({ treqId, formData }))
            .unwrap()
            .then(() => {
              setModalHeader("Success");
              setModalContent(
               <StatusMessage key={countdown} />
              );
              setIsError(false);
              setModalVisible(true);
            });
        } else {
          setModalHeader("Success");
          setModalContent(
            <StatusMessage key={countdown}    /> );
          setIsError(false);
          setModalVisible(true);
        }
      })
      .catch((error: any) => {
        setModalHeader("");
        setModalContent(`${error}. Please try again`);
        setIsError(true);
        setModalVisible(true);
      });
  };

  if (!initialValues) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const renderButtons = () => {
    const buttonsList = {
      save: {
        onClick: () => setActionType(1),
        className: "btn icn-save",
        type: "submit",
        text: "Save",
        id: "force-save-button",
      },
      submit: {
        onClick: () => setActionType(2),
        className: "btn icn-savesub",
        type: "submit",
        text: "Submit",
        id: "force-submit-button",
      },
      delete: {
        onClick: handleDelete,
        className: "btn icn-delete",
        type: "button",
        text: "Delete",
      },
      accept: {
        onClick: () => setActionType(3),
        className: "btn icn-save",
        type: "submit",
        text: "Accept",
      },
      print: {
        id: "btnPrint",
        onClick: handlePrint,
        className: "btn icn-print",
        type: "button",
        text: "Print",
      },
      saveChange: {
        onClick: () => setActionType(5),
        className: "btn icn-save",
        type: "submit",
        text: "Save Changes",
      },
      reOpen: {
        onClick: handleReopen,
        className: "btn icn-edit",
        type: "button",
        text: "ReOpen T-Req",
      },
      close: {
        onClick: () => setActionType(4),
        className: "btn icn-close",
        type: "submit",
        text: "Close T-Req",
      },
      uploadAndPay: {
        id: "btnUploadCodePay",
        onClick: openConfirmModal,
        className: "btn icn-upload",
        type: "button",
        text: "Upload to Code & Pay",
      },
      confirm: {
        onClick: () => setActionType(6),
        className: "btn icn-confirm",
        type: "submit",
        text: "Confirm T-Req",
      },
      clone: {
        id: "btnClone",
        onClick: handleClone,
        className: "btn icn-clone",
        type: "button",
        text: "Clone",
      },
      cancel: {
        id: "btnCancel",
        onClick: () => window.location.href= "/treq-listing",
        className: "btn icn-cancel",
        type: "button",
        text: "Cancel",
      },
    };

    const commonButtons =  user?.SchedulinkInfo?[]:[
      ...(userRole !== ROLES.DISPATCHER ? [buttonsList.clone] : []),
      buttonsList.cancel,
    ];

    const statusSpecificButtons = {
      1: [buttonsList.save, buttonsList.submit, buttonsList.delete],
      2: [
        userRole !== ROLES.REQUESTOR
          ? buttonsList.accept
          : buttonsList.saveChange,
        buttonsList.print,
      ],
      3: [buttonsList.saveChange, buttonsList.confirm, buttonsList.print],
      5: [
        userRole !== ROLES.REQUESTOR ? buttonsList.close : null,
        userRole !== ROLES.REQUESTOR ? buttonsList.reOpen : null,
        buttonsList.saveChange,
        buttonsList.print,
      ],
      default: [
        userRole !== ROLES.REQUESTOR && userRole !== ROLES.DISPATCHER
          ? apiData?.generalData?.isUploadedToLogistic===true?null:buttonsList.uploadAndPay
          : null,
        buttonsList.print,
        userRole !== ROLES.REQUESTOR ? buttonsList.reOpen : null,
      ],
    };

    const buttons = [
      ...(statusSpecificButtons[apiData?.generalData?.status] ||
        statusSpecificButtons.default),
      ...commonButtons,
    ].filter(Boolean);




    return (
      <div className="form-group btns">
        <div className="col-sm-10 mg-btm40">
          {buttons.map((button, index) => (
            <>
              <button
                key={index}
                id={button.id}
                onClick={button.onClick}
                className={button.className}
                type={button.type}
                disabled={formSubmission.loading}
              >
                {button.text}
              </button>
              &nbsp;
            </>
          ))}
        </div>
      </div>
    );
  };

  const status = apiData?.generalData?.status;

  return (
    <div className="page-content overflow treq-table">
      {formSubmission.loading && (
        <div className="overlay">
          <div className="loading-icon"></div>
        </div>
      )}
      <h3 className="page-title">Edit T-Req</h3>
      <hr className="hr" />
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(isBrazilUser)}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form className="form-horizontal white content-border padding15 padding-btm5" onChange={handleFormChange}>
            {isBrazilUser ? (
              <>
                <GeneralInformation
                  edit={true}
                  readOnly={status > 3}
                  treqId={apiData?.generalData?.requestid}
                  dateNeeded=""
                  statusOfTreq={status}
                  dateSubmitted={apiData?.generalData?.dateSubmitted}
                  isBrazilUser={true}
                />
                <TransportationInfo readOnly={[4, 5, 6].includes(status)} />
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-9">
                    <GeneralInformation
                      edit={true}
                      readOnly={status > 3}
                      statusOfTreq={status}
                      treqId={apiData?.generalData?.requestid}
                      dateNeeded=""
                      dateSubmitted={apiData?.generalData?.dateSubmitted}
                    />
                  </div>
                  <FlightInformation readOnly={status > 3} />
                </div>
                <TravelInformation readOnly={[4, 5, 6].includes(status)} />
                <VehicleInformation readOnly={status === 4} />
              </>
            )}
            <Attachment readOnly={[4, 5, 6].includes(status)} />
            {renderButtons()}
          </Form>
        )}
      </Formik>
      <CommonModal
        visible={modalVisible}
        header={modalHeader}
        content={
          <>
            {modalContent}
            {showAlternateSubmit && (
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn icn-save"
                  id="btnContinue"
                  onClick={handleForceSubmit}
                >
                  Continue
                </button>
                <button
                  type="button"
                  className="btn icn-cancel"
                  data-dismiss="modal"
                  onClick={handleModalClose}
                >
                  &nbsp;Cancel
                </button>
              </div>
            )}
          </>
        }
        onClose={handleModalClose}
        showCloseButton={!(isError && !forceSubmit)}
      />
    </div>
  );
};

export default EditTreq;
