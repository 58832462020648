import {  useDispatch, useSelector } from 'react-redux';
import { RootState,store } from '../store';
import {logout} from '../features/auth/authSlice'


export function getTokenDuration(): number {
  const storedExpirationDate: string | null = localStorage.getItem('expiration');
  if (!storedExpirationDate) {
    return 0; // Handle case where expiration date is not found
  }
  const expirationDate: Date = new Date(storedExpirationDate);
  const now: Date = new Date();
  const duration: number = expirationDate.getTime() - now.getTime();
  return duration;
}

export function getAuthToken() {
  const state = store.getState();
  return state.auth.user?.token;
}


export const useAuth =  () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const isLoading = useSelector((state: RootState) => state.auth.isLoading);
  const error = useSelector((state: RootState) => state.auth.error);

  const isAuthenticated = !!user && !!user.token;

  const logOut = () => {
    dispatch(logout());
    localStorage.removeItem('user');
    //localStorage.removeItem('msalaccesstoken'); 
  }

  return{
    isAuthenticated,
    user,
    isLoading,
    error,
    logOut
  }
}