import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleAxiosError,updateBaseUrl } from './axiosInstance';  // Adjust path as necessary

export default function createDataFetchThunk<ResultType, ArgType = undefined>(
  actionName: string,
  fetchFunction: (arg?: ArgType) => Promise<ResultType>,
  usePremiumApi: number = 0
) {
  return createAsyncThunk<ResultType, ArgType, { rejectValue: string }>(
    actionName,
    async (arg, { rejectWithValue }) => {
      try {
        updateBaseUrl(usePremiumApi);
        return await fetchFunction(arg);
      } catch (error) {
        return rejectWithValue(handleAxiosError(error));
      }
    }
  );
}
