import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import SelectField from "./SelectField";
import DatePickerField from "./DatePickerField";
import TimeField from './TimeField';
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { tranformData } from '../utils/common';


interface FlightInformationProps {
  readOnly: boolean; 
}

const FlightInformation: React.FC<FlightInformationProps> = ({readOnly=false}) => {
  const [airlines, setAirlines] = useState<{ id: string; value: string }[]>([]);
  const { udcType } = useSelector((state: RootState) => state.formData);
  useEffect(() => {
    if (udcType.length > 0) {
    const transformedAirlines = tranformData(udcType, 'Airline');
      setAirlines(transformedAirlines);
    }
  }, [udcType]);

  return (
    <div className="col-sm-3 form-horizontal white content-border padding15" id="divFlightInfo">
      <fieldset>
        <legend>Flight Information</legend>
          <>
            <div className="form-group">
              <SelectField
                name="airlinecode"
                options={airlines}
                valueField="id"
                labelField="value"
                label="Airline/Carrier"
                className="col-sm-9"
                labelClassName="col-sm-3"
                readOnly={readOnly}
              />
            </div>
            <div className="form-group">
              <InputField label="Flight #" name="fltnumber" className="col-sm-9" labelClassName="col-sm-3"  readOnly={readOnly} />
            </div>
            <div className="form-group">
              <InputField label="From" name="FlightFrom" className="col-sm-9" labelClassName="col-sm-3"  readOnly={readOnly} />
            </div>
            <div className="form-group">
              <InputField label="To" name="FlightTo" className="col-sm-9" labelClassName="col-sm-3"  readOnly={readOnly} />
            </div>
            <div className="form-group">
              <DatePickerField
                label="ETA/ETD"
                name="etaDate"
                dateFormat="yy-mm-dd"
                className="col-sm-5"
                labelClassName="col-sm-2"
                readOnly={readOnly}
              />
              <TimeField
                label=""
                name="etdTime"
                className="col-sm-5"
                readOnly={readOnly}
              />
            </div>
          </>
      </fieldset>
    </div>
  );
};

export default FlightInformation;