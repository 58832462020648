import React,{ useEffect } from 'react';
import { Calendar } from 'primereact/calendar';
import { useField, useFormikContext } from 'formik';

interface TimeFieldProps {
  label: string;
  name: string;
  showSeconds?: boolean;
  className?: string;
  labelClassName?: string;
  readOnly?: boolean;
}

const TimeField: React.FC<TimeFieldProps> = ({ label, name, showSeconds = false ,className = 'col-sm-3',labelClassName = 'col-sm-1', readOnly="false" }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (field.value && typeof field.value === 'string') {
      const date = new Date(field.value);
      if (!isNaN(date.getTime())) {
        setFieldValue(name, date);
      } else {
        const [hours, minutes] = field.value.split(':').map(Number);
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        setFieldValue(name, date);
      }
    }
  }, [field.value, setFieldValue, name]);

  const handleChange = (e: any) => {
    setFieldValue(name, e.value);
  };

  return (
    <>
     {label && <label htmlFor={name} className={`control-label ${labelClassName} fw-norm p-lr5`}>{label}</label>}
      <div className={className}>
        <Calendar
          {...field}
          id={name}
          value={field.value || null} 
          onChange={handleChange}
          timeOnly
          showSeconds={showSeconds}
          hourFormat="24"
          className={`form-controls ${meta.touched && meta.error ? 'is-invalid' : ''}`}
          showIcon
          disabled={readOnly}
        />
        {meta.touched && meta.error && (
          <div className="help-block error-msg">{meta.error}</div>
        )}
      </div>
    </>
  );
};

export default TimeField;
