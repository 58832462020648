import React from 'react';
import { formatDate } from "../../src/utils/common";

interface GeneralInformationDetailProps {
  data: any;
}

const GeneralInformationDetail: React.FC<GeneralInformationDetailProps> = ({ data }) => {


  return (
    <div id="genInfoId" className="form-horizontal white content-border padding15">
      <fieldset>
        <legend>Summary Information</legend>
        <div className="form-group">
          <label className="control-label col-sm-1 fw-norm p-lr5">T-Req ID:</label>
          <div className="col-md-3">
            <p className="form-control-static">{data.requestid ? data.requestid : 'New Request'}</p>
          </div>
          <label className="control-label col-sm-1 fw-norm p-lr5">Project</label>
          <div className="col-sm-3">
            <p className="form-control-static">{data.project}</p>
          </div>
          <label className="control-label col-sm-1 fw-norm p-lr5">Description</label>
          <div className="col-sm-2">
            <p className="form-control-static">{data.projectName}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-1 fw-norm p-lr5">Submitted:</label>
          <div className="col-md-3">
            <p className="form-control-static">{data.dateSubmitted ? formatDate(data.dateSubmitted) : 'Not Submitted'}</p>
          </div>
          <label className="control-label col-sm-1 fw-norm p-lr5">Activity</label>
          <div className="col-sm-3">
            <p className="form-control-static">{data.activity}</p>
          </div>
          <label className="control-label col-sm-1 fw-norm p-lr5">Description</label>
          <div className="col-sm-2">
            <p className="form-control-static">{data.activityDescription}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-1 fw-norm p-lr5">Modified</label>
          <div className="col-sm-3">
            <p className="form-control-static">{formatDate(data.datemodified)}</p>
          </div>
          <label className="control-label col-sm-1 fw-norm p-lr5">Requestor:</label>
          <div className="col-sm-3">
            <p className="form-control-static">{data.requestor}</p>
          </div>
          <label className="control-label col-sm-1 fw-norm p-lr5"></label>
          <div className="col-sm-2">
            <p className="form-control-static"></p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-1 fw-norm p-lr5">Current</label>
          <div className="col-sm-3">
            <p className="form-control-static">{data.iscurrent?"Yes":"No"}</p>
          </div>
          <label className="control-label col-sm-1 fw-norm p-lr5">Client:</label>
          <div className="col-sm-3">
            <p className="form-control-static">{data.clientid}</p>
          </div>
          <label className="control-label col-sm-1 fw-norm p-lr5"></label>
          <div className="col-sm-2">
            <p className="form-control-static"></p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-1 fw-norm p-lr5">Mission ID:</label>
          <div className="col-sm-3">
            <p className="form-control-static">{data.missionid}</p>
          </div>
          <label className="control-label col-sm-1 fw-norm p-lr5">Req. Author</label>
          <div className="col-sm-7">
            <p className="form-control-static"></p>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default GeneralInformationDetail;
