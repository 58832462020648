import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "../components/InputField";
import SelectField from "../components/SelectField";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import axiosInstance from "../api/axiosInstance";
import {
  updateUser,
  fetchUsers,
  selectUsers,
  User,
  selectUserLoading,
} from "../features/users/userSlice";
import { showNotification } from "../features/notification/notificationSlice";
import { store } from "../store";
import { ENDPOINTS } from "../api/config";
import { getRoles } from "../constants/roles";
import { useGraphToken} from '../hooks/useGraphToken'
import {getRoleIdByRoleName,showNotificationWithTimeout} from "../utils/common"

const USER_TYPES = {
  INTERNAL: 0,
  EXTERNAL: 1,
} as const;

type UserType = (typeof USER_TYPES)[keyof typeof USER_TYPES];

const ManageUsers: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const users = useSelector(selectUsers);
  const loading = useSelector(selectUserLoading);
  const [userType, setUserType] = useState<UserType>(USER_TYPES.INTERNAL);
  const [editUser, setEditUser] = useState<any | null>(null);
  const {generateGraphToken} = useGraphToken();
  const [formSubmitting,setFormSubmitting]=useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchUsers(userType));
  }, [userType, dispatch]);

  const handleLookup = async (values: {
    userName: string;
    employeeId: string;
  }) => {
    try {
      const response = await axiosInstance.get(ENDPOINTS.GET_LOOKUP_DATA, {
        params: { ...values },
      });
      if (response.data) {
        setEditUser(response.data);
      }
    } catch (error) {
      store.dispatch(
        showNotification({
          message: "Error fetching user data" + error.toString(),
          type: "error",
        })
      );
    }
  };

  const handleEdit = (user: User) => {
    setEditUser(user);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
  });

  const handleUserTypeChange = (type: UserType, resetForm: () => void) => {
    setUserType(type);
    setEditUser(null);
    resetForm();
  };

  const roleOptions =
    userType === USER_TYPES.INTERNAL ? getRoles() : getRoles("DISPATCHER");

  return (
    <div className="page-content-wrapper">
      <div className="page-content overflow">
        <div className="row">
          <div className="col-md-12">
            <h3 className="page-title">Manage Users</h3>
          </div>
        </div>
        <hr className="hr" />
        <div className="form-group col-md-12">
         
            <input
              type="radio"
              id="oceaneering"
              name="userType"
              value={USER_TYPES.INTERNAL}
              checked={userType === USER_TYPES.INTERNAL}
              style={{float: 'left'}}
              onChange={() =>
                handleUserTypeChange(USER_TYPES.INTERNAL, () => {})
              }
            />
         
          <label className="control-label col-sm-1">Internal Users:</label>
         
            <input
              type="radio"
              id="external"
              name="userType"
              value={USER_TYPES.EXTERNAL}
              checked={userType === USER_TYPES.EXTERNAL}
              style={{float: 'left'}}
              onChange={() =>
                handleUserTypeChange(USER_TYPES.EXTERNAL, () => {})
              }
            />
          
          <label className="control-label col-sm-1">External Users:</label>
        </div>
        <hr className="hr" />

        {userType === USER_TYPES.INTERNAL && (
          <Formik
            initialValues={{
              userName: editUser?.userName || "",
              employeeId: editUser?.employeeId || "",
            }}
            enableReinitialize
            onSubmit={handleLookup}
          >
            {(formikProps) => (
              <div className="form-horizontal white content-border padding15">
                <div className="form-group">
                  <InputField label="UserName:" name="userName" />
                  <InputField label="Employee ID:" name="employeeId" />
                  <div className="col-sm-2 req-mgt5">
                    <button
                      className="btn icn-browse"
                      type="button"
                      disabled={
                        !formikProps.values.userName &&
                        !formikProps.values.employeeId
                      }
                      onClick={formikProps.submitForm}
                    >
                      Lookup
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        )}

        <Formik
          initialValues={{
            firstName: editUser?.firstName || "",
            lastName: editUser?.lastName || "",
            isEnabled: editUser?.status==='Active'? true: false,
            roleId: getRoleIdByRoleName(editUser?.roleName) || (getRoleIdByRoleName(editUser?.role))||1,
            delete: editUser ? editUser.delete : false,
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values, { setSubmitting , resetForm }) => {
            setFormSubmitting(true);
            const formData = { ...values, userType };
            if (userType === USER_TYPES.INTERNAL) {
              formData.employeeId = editUser?.employeeId;
              formData.userName = editUser?.userName;
              formData.userType=userType;
              formData.roleName = roleOptions.find(
                (role) => role.id === parseInt(values.roleId)
              )?.label;
              delete formData.isEnabled;
              formData.isDeleteEnabled = values.delete ? 1 : 0;
              delete formData.delete;
            } else {
              delete formData.delete;
              formData.isEnabled = values.isEnabled ? 1 : 0;
              formData.id = editUser?.id;
              formData.email=editUser?.email;
              formData.userType=userType;
            }
            const graphToken = await generateGraphToken();
            const headers =
              userType === USER_TYPES.INTERNAL ? { Graphtoken: graphToken } : {};

            dispatch(updateUser({ userData: formData, headers })).then(() => {
              setFormSubmitting(false);
              setEditUser(null);
              resetForm();
              showNotificationWithTimeout(dispatch, 'User updated successfully', 'success');
              
              dispatch(fetchUsers(userType));
            });
          }}
        >
          {(formikProps) => (
            <Form>
              <div className="row">
                {formSubmitting &&  <div className="overlay">
          <div className="loading-icon"></div>
        </div>}
                <div className="col-md-12 overflow">
                  <div className="form-horizontal white content-border padding15">
                    <div className="form-group">
                      <InputField
                        label="First Name:"
                        name="firstName"
                        readOnly={
                          editUser === null || userType === USER_TYPES.INTERNAL
                        }
                      />
                      <InputField
                        label="Last Name:"
                        name="lastName"
                        readOnly={
                          editUser === null || userType === USER_TYPES.INTERNAL
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label col-sm-1">
                        {userType === USER_TYPES.INTERNAL
                          ? "Delete:"
                          : "Enabled:"}
                      </label>
                      <div className="col-md-3">
                        <input
                          type="checkbox"
                          name={
                            userType === USER_TYPES.INTERNAL
                              ? "delete"
                              : "isEnabled"
                          }
                          checked={
                            formikProps.values[
                              userType === USER_TYPES.INTERNAL
                                ? "delete"
                                : "isEnabled"
                            ]
                          }
                          onChange={(e) => {
                            formikProps.setFieldValue(
                              userType === USER_TYPES.INTERNAL
                                ? "delete"
                                : "isEnabled",
                              e.target.checked
                            );
                          }}
                          className="mg-top10"
                        />
                      </div>
                      <SelectField
                        label="Roles:"
                        name="roleId"
                        skipChoose={true}
                        options={roleOptions.map((role) => ({
                          value: role.id,
                          label: role.label,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 txt-right form-group">
                    <button
                      type="reset"
                      className="btn icn-refresh"
                      disabled={editUser === null}
                      onClick={() => setEditUser(null)}
                    >
                      Clear
                    </button>
                    &nbsp;
                    <button type="submit" className="btn icn-save"  disabled={editUser === null}>
                      Save
                    </button>
                  </div>
                </div>
                
                <div className="col-md-12 form-group">
                <DataTable
  value={users}
  loading={loading}
  globalFilterFields={[
    "firstName",
    "lastName",
    "email",
    "roleName",
    "status",
  ]}
  paginator
  rows={10}
  rowsPerPageOptions={[10, 20, 50, 100]}
  sortMode="multiple"
  emptyMessage="No user found."
>
  {userType === USER_TYPES.INTERNAL && (
    <Column
      field="employeeId"
      header="Employee ID"
      filter
      filterPlaceholder="Employee ID"
      filterMatchMode="contains"
      sortable
    ></Column>
  )}
  <Column
    field="firstName"
    filter
    filterPlaceholder="First Name"
    filterMatchMode="contains"
    header="First Name"
    sortable
  ></Column>
  <Column
    field="lastName"
    filter
    filterPlaceholder="Last Name"
    filterMatchMode="contains"
    header="Last Name"
    sortable
  ></Column>
  <Column
    field="email"
    filter
    filterPlaceholder="Email"
    filterMatchMode="contains"
    header="Email"
    sortable
  ></Column>
  <Column
    field="roleName"
    header="Role"
    filter
    filterPlaceholder="Role"
    filterMatchMode="contains"
    sortable
  ></Column>
  <Column
    field="status"
    header="Status"
    filter
    filterPlaceholder="Status"
    filterMatchMode="contains"
    sortable
  ></Column>
  {userType === USER_TYPES.INTERNAL && (
    <Column
      field="userName"
      header="UserName"
      filter
      filterPlaceholder="UserName"
      filterMatchMode="contains"
      sortable
    ></Column>
  )}
  <Column
    header="Action"
    body={(rowData: User) => (
      <>
        <button type="button" onClick={() => handleEdit(rowData)}>
          Edit User
        </button>
      </>
    )}
  ></Column>
</DataTable>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ManageUsers;
