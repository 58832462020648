import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import DatePickerField from "../components/DatePickerField";
import SelectField from "../components/SelectField";
import { fetchDivisions } from "../features/dashboard/dashboardSlice";
import axiosInstance, { updateBaseUrl } from "../api/axiosInstance";
import { ENDPOINTS } from "../api/config";
import { formatDateAPI } from "../utils/common";
import { store, AppDispatch, RootState } from "../store";
import { showNotification } from "../features/notification/notificationSlice";
import { useAuth } from "../utils/auth";

const DetailReport: React.FC = () => {
  const { user } = useAuth();
  const isBrazilUser = user?.isBrazilUser;
  const dispatch = useDispatch<AppDispatch>();
  const { divisions } = useSelector((state: RootState) => state.dashboard);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isBrazilUser) {
      dispatch(fetchDivisions());
    }
  }, [dispatch, isBrazilUser]);

  const initialValues = {
    startDate: "",
    endDate: "",
    udc: "",
  };

  const validationSchema = Yup.object({
    startDate: Yup.date().required("Required").nullable(),
    endDate: Yup.date()
      .required("Required")
      .nullable()
      .min(Yup.ref("startDate"), "To date must be later than from date"),
    udc: Yup.string().when([], {
      is: () => isBrazilUser === 1,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const handleGenerateReport = async (values: any) => {
    const { startDate, endDate, udc } = values;
    const formattedStartDate = formatDateAPI(startDate);
    const formattedEndDate = formatDateAPI(endDate);
    setLoading(true);
    try {
      updateBaseUrl(0);
      const response = await axiosInstance.get(
        `${ENDPOINTS.GENERATE_DETAIL_REPORT}`,
        {
          params: {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            udc: isBrazilUser ? udc : undefined,
          },
          responseType: "blob",
        }
      );
      const xlsxBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(xlsxBlob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      const filename = `DetailReport_${formattedStartDate}_${formattedEndDate}.xlsx`;
      tempLink.setAttribute("download", filename);

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      store.dispatch(
        showNotification({
          message: "Failed to download XLSX: " + error,
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content overflow treq-table">
      {loading && (
        <div className="overlay">
          <div className="loading-icon"></div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <h3 className="page-title">Detail Report </h3>
        </div>
      </div>
      <hr className="hr" />
      <div className="row">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleGenerateReport}
        >
          <Form className="form-horizontal">
            <div className="form-group">
              <DatePickerField
                label="From:"
                name="startDate"
                className="col-sm-2 "
                labelClassName="col-sm-1"
               
              />
            </div>
            <div className="form-group">
              <DatePickerField
                label="To:"
                name="endDate"
                className="col-sm-2"
                labelClassName="col-sm-1"
               
              />
            </div>
            <div className="form-group">
              {isBrazilUser === 1 && (
                <SelectField
                  label="Division"
                  name="udc"
                  options={divisions}
                  valueField="divisionCode"
                  labelField="description"
                  className="col-sm-2"
                  labelClassName="col-sm-1"
                />
              )}
            </div>
            <div className="form-group">
            <label  className="control-label col-sm-1 fw-norm p-lr5"></label>
              <div className="col-sm-2">
                <button className="btn icn-search" type="submit" id="btnSearch">
                  Generate Report
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default DetailReport;
