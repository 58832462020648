import React, { Suspense, lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchDivisions, fetchSystems } from '../features/dashboard/dashboardSlice'; 
import { AppDispatch } from '../store';
import Loading from '../components/Loading';
import StatusChart from '../components/dashboard/StatusChart'
import DriverChart from '../components/dashboard/DriverChart'
import MonthlyChart from '../components/dashboard/MonthlyChart'
import OrderSummary from '../components/dashboard/OrderSummary'
import LastModifiedDataTable from '../components/dashboard/LastModified';

import { useAuth } from '../utils/auth'


const BrazilMonthlyCharts = lazy(() => import('../components/dashboard/BrazilMonthlyCharts'));
const BrazilDivisionCharts = lazy(() => import('../components/dashboard/BrazilDivisionCharts'));
const BrazilProjectCharts = lazy(() => import('../components/dashboard/BrazilProjectCharts'));


const DashboardPage: React.FC = () => {


  const { user } = useAuth();
  const isBrazilUser=user?.isBrazilUser;

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if(isBrazilUser){
    dispatch(fetchDivisions());
    dispatch(fetchSystems());
    }
  }, [dispatch,isBrazilUser]);


  return (
    <div className='dashboardPage'>
      <div className="page-content overflow">
       
          <div className="container-fluid fade in">
            <div className="row">
              <div className="col-md-12">
                <h3 className="page-title">Dashboard </h3>
              </div>
            </div>
            <hr className="hr" />
            <div className="wrapper wrapper-content animated fadeIn">
              <div className="clearfix"></div>
              <div className="col-md-12">
                <OrderSummary />
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="demo-container col-lg-6 col-md-6">
                    <StatusChart />
                  </div>
                  <div className="demo-container_chart col-lg-6 col-md-6">
                    <DriverChart />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="demo-container col-lg-6 col-md-6">
                    <h2>Top 10 Last modified Treq's</h2>
                    <div className="scroll_treq">
                      <LastModifiedDataTable />
                    </div>
                  </div>
                  <div className="demo-container_chart col-lg-6 col-md-6">
                    <MonthlyChart />
                  </div>
                </div>
              </div>
              {isBrazilUser===1 && (
                 <Suspense fallback={<Loading />}>
                <div className="col-lg-12" style={{ marginTop: '50px' }}>
                  <div className="row">
                    <BrazilMonthlyCharts />
                    <BrazilDivisionCharts />
                  </div>
                  <div className="row">
                    <BrazilProjectCharts />
                  </div>
                </div>
                </Suspense>
              )}
            </div>
          </div>
        
      </div>
    </div>
  );
};

export default DashboardPage;
