import React,{useState} from "react";
import TreqForm from "../components/TreqForm";
import TreqTable from "../components/TreqTable";
import { fetchOldTreqData } from '../features/treq/oldTreqSlice';
import { RootState } from '../store';

const OldTreqListingPage: React.FC = () => {
  const [filterParams, setFilterParams] = useState<{ startDate: string; endDate: string; treqId: string }>({ startDate: '', endDate: '', treqId: '' });
  const selector = (state: RootState) => state.oldTreq;
  const handleFilterParams = (startDate: string, endDate: string, treqId: string) => {
    setFilterParams({ startDate, endDate, treqId });
  };

  return (
    <div className="page-content overflow">
      <div className="row">
        <div className="col-md-12">
          <h3 className="page-title">Old TREQ Listing Page </h3>
        </div>
      </div>
      <hr className="hr" />
      <div className="row">
        <TreqForm fetchData={fetchOldTreqData} setFilterParams={handleFilterParams} />
        <TreqTable fetchData={fetchOldTreqData} selector={selector} showPrintButton={false} isOldTreq={true} filterParams={filterParams}   />
      </div>
    </div>
  );
};

export default OldTreqListingPage;
