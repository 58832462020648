import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth } from "../utils/auth";
import { AppDispatch } from "../store";
import SignInButton from "../components/SignInButton";
import Loading from "../components/Loading";
import { useMsal } from "@azure/msal-react";

import { login } from "../features/auth/authSlice";
import { showNotification } from "../features/notification/notificationSlice";
import { store } from "../store";


function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { instance, accounts, inProgress } = useMsal();



  useEffect(() => {
    if (inProgress !== "none") return;

    const queryParams = new URLSearchParams(window.location.search);
    const paramsObject: any = {};
    queryParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    if (isAuthenticated) {
      if (user?.SchedulinkInfo) {
        setLoading(true);

        const checkLogin = async (token) => {
          try {
            const formData = {
              token: token,
              ...paramsObject,
            };
            const loginData = await dispatch(login(formData)).unwrap();
            
            if (loginData?.SchedulinkInfo.treqId === 0) {
              navigate("/create-treq");
            } else {
              navigate(`/treq/edit/${user.SchedulinkInfo.treqId}`);
            }
          } catch (error) {
            store.dispatch(showNotification({ message: error, type: "error" }));
          } finally {
            setLoading(false);
          }
        };

        checkLogin(user.token);
      } else {
        if (paramsObject.treqId) {
          navigate(`/treq/edit/${encodeURIComponent(paramsObject.treqId)}`);
        } else navigate("/dashboard");
      }
    }
  }, [
    navigate,
    isAuthenticated,
    dispatch,
    instance,
    accounts,
    inProgress,
    user,
  ]);

  return (
    <>
      {isLoading || loading ? (
        <Loading />
      ) : (
        <div className="container-fluid">
          <div className="row-fluid" style={{ display: "block" }}>
            <div className="form-signin padding mg-tb200">
              <div className="checkbox btn-middle">
                  <SignInButton />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginPage;
