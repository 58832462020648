import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  addGroup,
  editGroup,
  fetchGroups,
  deleteGroup,
  fetchGroupMembers,
  deleteGroupMembers,
} from "../features/groups/groupSlice";
import { AppDispatch, RootState } from "../store";
import InputField from "../components/InputField";
import axiosInstance, { updateBaseUrl } from "../api/axiosInstance";
import { ENDPOINTS } from "../api/config";
import { showNotification } from "../features/notification/notificationSlice";

const ManageGroup: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { groups, groupLoading } = useSelector(
    (state: RootState) => state.groups
  );
  const [addEditDialog, setAddEditDialog] = useState(false);
  const [addMemberDialog, setAddMemberDialog] = useState(false);
  const [viewDeleteMemberDialog, setViewDeleteMemberDialog] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [currentGroupId, setCurrentGroupId] = useState<string | null>(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userSearchResults, setUserSearchResults] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [selectedGroupMembers, setSelectedGroupMembers] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  const openAddEditDialog = (group: any = null) => {
    if (group) {
      setGroupName(group.groupName);
      setCurrentGroupId(group.Id);
    } else {
      setGroupName("");
      setCurrentGroupId(null);
    }
    setAddEditDialog(true);
  };

  const openAddMemberDialog = (group) => {
    setGroupName(group.groupName);
    setCurrentGroupId(group.Id);
    setAddMemberDialog(true);
  };

  const openViewDeleteMemberDialog = async (groupId: string) => {
    setCurrentGroupId(groupId);
    dispatch(fetchGroupMembers(groupId))
      .then((members) => {
        setGroupMembers(members.payload);
        setViewDeleteMemberDialog(true);
      })
      .catch((error) => {
        dispatch(showNotification({ message: error.message, type: "error" }));
      });
  };

  const handleAddEditSubmit = async (values: { groupName: string }) => {
    const groupId = currentGroupId ? currentGroupId : 0;
    const action = groupId
      ? editGroup({ groupId: groupId, groupName: values.groupName })
      : addGroup({ groupName: values.groupName, groupId: 0 });

    try {
      await dispatch(action).unwrap();
      dispatch(
        showNotification({
          message: groupId
            ? "Group updated successfully"
            : "Group added successfully",
          type: "success",
        })
      );
      dispatch(fetchGroups());
      setAddEditDialog(false);
    } catch (error) {
      dispatch(showNotification({ message: error.message, type: "error" }));
    }
  };

  const handleDeleteGroup = async () => {
    if (groupToDelete) {
      try {
        await dispatch(deleteGroup({ groupId: groupToDelete })).unwrap();
        dispatch(
          showNotification({
            message: "Group deleted successfully",
            type: "success",
          })
        );
        dispatch(fetchGroups());
        setConfirmDelete(false);
      } catch (error) {
        dispatch(showNotification({ message: error.message, type: "error" }));
      }
    }
  };

  const handleSearchUsers = async (values: {
    firstName: string;
    lastName: string;
  }) => {
    try {
      updateBaseUrl(0);
      const response = await axiosInstance.get(ENDPOINTS.SEARCH_USER, {
        params: {
          firstName: values.firstName,
          lastName: values.lastName,
          page: 1,
          count: 1000,
          size: 10,
        },
      });
      setUserSearchResults(response.data);
    } catch (error) {
      dispatch(
        showNotification({ message: "An API error occurred", type: "error" })
      );
      setUserSearchResults([]);
    }
  };

  const handleAddMembersSubmit = async () => {
    if (currentGroupId) {
      try {
        const userIds = selectedUsers.map((user) => user.employee_id);
        updateBaseUrl(2);
        await axiosInstance.post(ENDPOINTS.ADD_GROUP_MEMBER, {
          groupId: currentGroupId,
          groupMemberId: userIds,
        });
        dispatch(
          showNotification({
            message: "Members added successfully",
            type: "success",
          })
        );
        setAddMemberDialog(false);
        setSelectedUsers([]);
      } catch (error) {
        dispatch(
          showNotification({
            message: "Error adding members to group",
            type: "error",
          })
        );
      }
    }
  };

  const handleDeleteMembersSubmit = async () => {
    console.log(selectedGroupMembers);
    if (currentGroupId) {
      try {
        await dispatch(
          deleteGroupMembers({
            groupId: currentGroupId,
            groupMemberId: selectedGroupMembers.map((user) => user.userId),
          })
        ).unwrap();
        setGroupMembers((prevMembers) =>
          prevMembers.filter((user) => !selectedGroupMembers.includes(user))
        );
        
        setSelectedGroupMembers([]);
        dispatch(
          showNotification({
            message: "Members deleted successfully",
            type: "success",
          })
        );
      } catch (error) {
        dispatch(showNotification({ message: error.message, type: "error" }));
      }
      finally{
        setViewDeleteMemberDialog(false);
      }
    }
  };

  const validationSchema = Yup.object({
    groupName: Yup.string().required("Group name is required"),
  });

  return (
    <div className="page-content-wrapper">
      <div className="page-content overflow">
        <div className="row">
          <div className="col-md-12">
            <h3 className="page-title">Manage Group</h3>
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "#5b7f95",
                color: "#fff",
                float: "right",
                marginBottom: "10px",
              }}
              onClick={() => openAddEditDialog()}
            >
              Add Group
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <DataTable
              value={groups}
              globalFilterFields={["name"]}
              loading={groupLoading}
              paginator
              rows={10}
              emptyMessage="No group found."
            >
              <Column
                field="groupName"
                filter
                filterPlaceholder="Search by Group Name"
                header="Group Name"
                sortable
                style={{width:'50%'}}
              ></Column>
              <Column
                header="Action"
                style={{width:'50%'}}
                body={(rowData) => (
                  <>
                    <button
                      type="button"
                      className="btn"
                      style={{
                        backgroundColor: "#5b7f95",
                        color: "#fff",
                        marginRight: "5px",
                      }}
                      onClick={() => openAddEditDialog(rowData)}
                    >
                      Edit Group
                    </button>
                    <button
                      type="button"
                      className="btn"
                      style={{
                        backgroundColor: "#5b7f95",
                        color: "#fff",
                        marginRight: "5px",
                      }}
                      onClick={() => {
                        setGroupToDelete(rowData.Id);
                        setConfirmDelete(true);
                      }}
                    >
                      Delete Group
                    </button>
                    <button
                      type="button"
                      className="btn"
                      style={{
                        backgroundColor: "#5b7f95",
                        color: "#fff",
                        marginRight: "5px",
                      }}
                      onClick={() => openAddMemberDialog(rowData)}
                    >
                      Add Members
                    </button>
                    <button
                      type="button"
                      className="btn"
                      style={{ backgroundColor: "#5b7f95", color: "#fff" }}
                      onClick={() => openViewDeleteMemberDialog(rowData.Id)}
                    >
                      View/Delete Members
                    </button>
                  </>
                )}
              ></Column>
            </DataTable>
          </div>
        </div>

        <Dialog
          header={currentGroupId ? "Edit Group" : "Add Group"}
          visible={addEditDialog}
          onHide={() => setAddEditDialog(false)}
        >
          <Formik
            initialValues={{ groupName: groupName }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleAddEditSubmit(values).finally(() => setSubmitting(false));
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <InputField
                  label="Group Name:"
                  name="groupName"
                  className="col-sm-6"
                  labelClassName="col-sm-2"
                />
                <button
                  type="submit"
                  className="btn"
                  style={{ backgroundColor: "#5b7f95", color: "#fff" }}
                  disabled={isSubmitting} // Disable button while submitting
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </Dialog>

        {/* Confirm Delete Dialog */}
        <Dialog
          header="Confirm Delete"
          visible={confirmDelete}
          onHide={() => setConfirmDelete(false)}
        >
          <p>Are you sure you want to delete this group?</p>
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: "#5b7f95",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleDeleteGroup}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn"
            style={{ backgroundColor: "#5b7f95", color: "#fff" }}
            onClick={() => setConfirmDelete(false)}
          >
            No
          </button>
        </Dialog>

        {/* Add Members Dialog */}
        <Dialog
          header="Add Members"
          visible={addMemberDialog}
          onHide={() => setAddMemberDialog(false)}
        >
          <Formik
            initialValues={{ firstName: "", lastName: "" }}
            onSubmit={handleSearchUsers}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-group">
                  <InputField label="First Name:" name="firstName" />
                  <InputField label="Last Name:" name="lastName" />
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      backgroundColor: "#5b7f95",
                      color: "#fff",
                      marginRight: "5px",
                    }}
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#5b7f95", color: "#fff" }}
                    onClick={() => setAddMemberDialog(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <DataTable
            value={userSearchResults}
            paginator
            rows={10}
            selection={selectedUsers}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onSelectionChange={(e) => setSelectedUsers(e.value)}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>
            <Column field="first_name" header="First Name"></Column>
            <Column field="last_name" header="Last Name"></Column>
          </DataTable>
          <button
            type="button"
            className="btn"
            style={{ backgroundColor: "#5b7f95", color: "#fff" }}
            onClick={handleAddMembersSubmit}
          >
            Save
          </button>
        </Dialog>

        {/* View/Delete Members Dialog */}
        <Dialog
          header="View/Delete Members"
          visible={viewDeleteMemberDialog}
          onHide={() => setViewDeleteMemberDialog(false)}
        >
          <DataTable
            paginator
            rows={10}
            totalRecords={groupMembers.length}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onSelectionChange={(e) => setSelectedGroupMembers(e.value)}
            value={groupMembers}
            style={{ minWidth: 500 }}
            selectionMode="multiple"
            selection={selectedGroupMembers}
            dataKey="id"
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>
            <Column field="firstName" header="First Name"></Column>
            <Column field="lastName" header="Last Name"></Column>
          </DataTable>
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: "#5b7f95",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleDeleteMembersSubmit}
          >
            Delete
          </button>
        </Dialog>
      </div>
    </div>
  );
};

export default ManageGroup;
