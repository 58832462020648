import React, { useState, useEffect } from 'react';
import InputField from './InputField';
import SelectField from './SelectField';
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { tranformData } from '../utils/common';

interface TravelInformationProps {
  readOnly: boolean; 
}

const TravelInformation: React.FC<TravelInformationProps> = ({ readOnly = false }) => {
  const { stateList: states, cityList: puCities, destinationList: destinations } = useSelector(
    (state: RootState) => state.formData
  );
  const [pickupCity, setPickupCity] = useState([]);
  const [deliveryCity, setDeliveryCity] = useState([]);
  const { values } = useFormikContext<any>();

  useEffect(() => {
    if (values.pickupState) {
      const transformedCity = tranformData(puCities, values.pickupState, 'stateId', 'cityName', 'cityId');
      setPickupCity(transformedCity);
    }
  }, [puCities, values.pickupState]);

  useEffect(() => {
    if (values.deliveryState) {
      const transformedCity = tranformData(puCities, values.deliveryState, 'stateId', 'cityName', 'cityId');
      setDeliveryCity(transformedCity);
    }
  }, [puCities, values.deliveryState]);

  const isOtherPickupCity = String(values.pickupCity) === '-2';
  const isOtherPickupLocation = String(values.pickupLocation) === '-2';
  const isOtherDeliveryCity = String(values.deliveryCity) === '-2';
  const isOtherDeliveryLocation = String(values.deliveryLocation) === '-2';

  return (
    <div className="col-md-12 form-horizontal white content-border padding15" id="divTravelInfo">
      <fieldset>
        <legend>Travel Information</legend>
        <div className="form-group">
          <SelectField label="P/U State" name="pickupState" options={states} valueField="statecode" labelField="statecode" readOnly={readOnly} />
          <SelectField label="P/U City" name="pickupCity" options={pickupCity} className={isOtherPickupCity ? 'col-sm-1' : 'col-sm-3'} valueField="id" labelField="value" includeOther readOnly={readOnly} />
          {isOtherPickupCity && <InputField name="otherPickupCity" className="col-sm-2" label="" readOnly={readOnly} />}
          <SelectField label="P/U Location" name="pickupLocation" options={destinations} className={isOtherPickupLocation ? 'col-sm-1' : 'col-sm-3'} valueField="id" labelField="value" includeOther readOnly={readOnly} />
          {isOtherPickupLocation && <InputField name="otherPickupLocation" className="col-sm-2" label="" readOnly={readOnly} />}
        </div>
        <div className="form-group">
          <SelectField label="Delivery State" name="deliveryState" options={states} valueField="statecode" labelField="statecode" readOnly={readOnly} />
          <SelectField label="Delivery City" name="deliveryCity" options={deliveryCity} className={isOtherDeliveryCity ? 'col-sm-1' : 'col-sm-3'} valueField="id" labelField="value" includeOther readOnly={readOnly} />
          {isOtherDeliveryCity && <InputField className="col-sm-2" label="" name="otherDeliveryCity" readOnly={readOnly} />}
          <SelectField label="Delivery Location" name="deliveryLocation" options={destinations} className={isOtherDeliveryLocation ? 'col-sm-1' : 'col-sm-3'} valueField="id" labelField="value" includeOther readOnly={readOnly} />
          {isOtherDeliveryLocation && <InputField name="otherDeliveryLocation" className="col-sm-2" label="" readOnly={readOnly} />}
        </div>
        <div className="form-group">
          <InputField label="Name of contact P/U Location" name="pickupContactName" readOnly={readOnly} />
          <InputField label="Contact Telephone #" name="contactTelephone" maskPhone readOnly={readOnly} />
        </div>
      </fieldset>
    </div>
  );
};

export default TravelInformation;
