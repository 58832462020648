import React from "react";
import { useField } from "formik";
import MaskedInput from "react-text-mask";

const usPhoneMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const brazilPhoneMask = [
  "(",
  /[1-9]/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

interface InputFieldProps {
  label: string;
  name: string;
  type?: string;
  className?: string;
  labelClassName?: string;
  numeric?: boolean;
  maskPhone?: boolean;
  readOnly?: boolean;
  required?: boolean;
  isBrazil?: boolean;
  decimal?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  name,
  type = "text",
  className = "col-sm-3",
  labelClassName = "col-sm-1",
  numeric = false,
  maskPhone = false,
  readOnly = false,
  required = false,
  isBrazil = false,
  decimal = false
}) => {
  const [field, meta, helpers] = useField(name);

  const phoneMask = isBrazil ? brazilPhoneMask : usPhoneMask;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (numeric) {
      const filtered = event.target.value.replace(/[^0-9]/g, "");
      helpers.setValue(filtered);
    }
    else if (decimal) {
      let matchValue = event.target.value.match(/[0-9]+([.][0-9]*)?/g);
      if (matchValue !== null) {
        const filtered = matchValue[0];
        helpers.setValue(filtered);
      }
      else
        helpers.setValue('');
    }
    else {
      helpers.setValue(event.target.value);
    }
  };

  return (
    <>
      {label && (
        <label
          htmlFor={name}
          className={`control-label ${labelClassName} fw-norm p-lr5`}
        >
          {label}
          {required && <>&nbsp;<span style={{ color: "red" }}>#</span></>}
        </label>
      )}
      <div className={className}>
        {maskPhone ? (
          <MaskedInput
            mask={phoneMask}
            {...field}
            id={name}
            type={type}
            className={`form-control ${meta.touched && meta.error ? "is-invalid" : ""
              }`}
            onChange={handleChange}
            readOnly={readOnly}
          />
        ) : (
          <input
            type={(numeric || decimal) ? "text" : type} // Set type to text to properly handle numeric input
            {...field}
            id={name}
            className={`form-control ${meta.touched && meta.error ? "is-invalid" : ""
              }`}
            onChange={handleChange}
            readOnly={readOnly}
          />
        )}
        {meta.touched && meta.error && (
          <div className="help-block error-msg">{meta.error}</div>
        )}
      </div>
    </>
  );
};

export default InputField;
