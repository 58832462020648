import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useCallback } from 'react';
import{GRAPH_SCOPES, GRAPH_SCOPES_ACCOUNT} from "../constants/global"

export const useGraphToken = () => {
  const { instance, accounts } = useMsal();

  const generateGraphToken = useCallback(async () => {
    const request = {
      scopes:GRAPH_SCOPES?.split(',') || [],
      account: accounts[GRAPH_SCOPES_ACCOUNT||0],
    };
    try {
      const response = await instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const response = await instance.acquireTokenPopup(request);
        return response.accessToken;
      } else {
        console.error('Error acquiring token:', error);
        throw error;
      }
    }
  }, [instance, accounts]);

  return { generateGraphToken };
};