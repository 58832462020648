import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MainNavigation from "../components/MainNavigation";
import { useAuth } from "../utils/auth";


function RootLayout() {

  const {isAuthenticated} = useAuth();
  return (
    <>
      <main>
        <Header />
        {isAuthenticated &&  <MainNavigation />}
        <Outlet />
        <Footer />
      </main>
    </>
  );
}

export default RootLayout;
