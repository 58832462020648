import React, { useState, useCallback } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axiosInstance,{updateBaseUrl} from '../api/axiosInstance';
import { ENDPOINTS } from '../api/config';

interface RequestorSearchModalProps {
  visible: boolean;
  onHide: () => void;
  onSelect: (requestor: any) => void;
}

const RequestorSearchModal: React.FC<RequestorSearchModalProps> = ({ visible, onHide, onSelect }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [results, setResults] = useState([]);
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);

  const handleSearch = useCallback(async () => {
    try {
      updateBaseUrl(0);
      const response = await axiosInstance.get(ENDPOINTS.SEARCH_USER, {
        params: { firstName, lastName, page:1, count:1000, size:10 },
      });
      setResults(response.data);
    } catch (error) {
      console.error("An API error occurred", error);
      setResults([]);
    }
  }, [firstName, lastName]);

  const handleFirstNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  }, []);

  const handleLastNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  }, []);

  const onPage = (event: any)=>{
    setFirst(event.first);
    setRows(event.rows);
  }

  return (
    <Dialog header="Search Requestor" visible={visible} style={{ width: '50vw' }} onHide={onHide}>
      <div className="form-group">
        <div className="col-md-4">
          <label htmlFor="firstName" className="control-label">First Name</label>
          <input id="firstName" className="form-control" value={firstName} onChange={handleFirstNameChange} />
        </div>
        <div className="col-md-4">
          <label htmlFor="lastName" className="control-label">Last Name</label>
          <input id="lastName" className="form-control" value={lastName} onChange={handleLastNameChange} />
        </div>
        <div className="p-col-4" style={{ display: 'flex', alignItems: 'center' }}>
          <button className="btn btn-primary" type='submit' onClick={handleSearch} style={{ marginTop: '20px' }}>Search</button>
        </div>
      </div>
      <DataTable value={results} selectionMode="single" paginator rows={rows} first={first} totalRecords={results.length} onPage={onPage} rowsPerPageOptions={[10,20,50,100]} sortMode='multiple' onRowSelect={(e) => onSelect(e.data)}>
        <Column field="first_name" header="First Name" sortable />
        <Column field="last_name" header="Last Name" sortable />
      </DataTable>
    </Dialog>
  );
};

export default RequestorSearchModal;
