// /features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store'; // Adjust the import path as necessary
import { authService } from '../../services/authService'; // Adjust based on your project structure
import { AuthState, User } from '../../models/user';



const initialState: AuthState = {
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') as string)
    : null,
  isLoading: false,
  error: null,
  isLogout:false
};

// Async thunk for handling login
export const login = createAsyncThunk<any, { token: string } & Record<string, any>>(
  'auth/login',
  async (params, { rejectWithValue }) => {
    try {
      const { token, ...rest } = params;
      const response = await authService.login({ token, ...rest });
      return response; 
    } catch (error) {
      return rejectWithValue('Login failed');
    }
  }
);
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      
      state.user = null;
      state.isLoading = false;
      localStorage.removeItem('user'); 
      //localStorage.removeItem('msalaccesstoken'); 
      state.isLogout=true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isLogout=true;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        const user = action.payload;
        state.isLogout=true;
        state.isLoading = false;
        state.user = user;
        state.error = null;
        localStorage.setItem('user', JSON.stringify(user)); // Store user data
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const rehydrateAuth = createAsyncThunk('auth/rehydrate', async (_, { dispatch }) => {
  const user = localStorage.getItem('user');
  if (user) {
    const userData = JSON.parse(user);
    if (userData.token) {
      dispatch(setUser(userData));
    }
  }
});

export const { logout, setUser } = authSlice.actions;

// Selector to check if the user is authenticated
export const selectIsAuthenticated = (state: RootState) => !!state.auth.user && !!state.auth.user.token;

export default authSlice.reducer;
