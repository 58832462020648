import React, { useState, useEffect } from "react";
import { useField } from "formik";

interface SelectFieldProps {
  label: string;
  name: string;
  options: any[];
  className?: string;
  labelClassName?: string;
  valueField?: string;
  labelField?: string;
  includeOther?: boolean;
  onOtherSelected?: (isOtherSelected: boolean) => void;
  readOnly?: boolean;
  required?: boolean;
  multiple?: boolean;
  skipChoose?:boolean;
}

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  name,
  options,
  className = "col-sm-3",
  labelClassName = "col-sm-1",
  valueField = "value",
  labelField = "label",
  includeOther = false,
  onOtherSelected,
  readOnly = false,
  required = false,
  multiple = false,
  skipChoose=false
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const [selectedValue, setSelectedValue] = useState<string | string[]>(field.value || "");

  useEffect(() => {
    setSelectedValue(field.value);
  }, [field.value]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    const value = multiple ? selectedOptions : selectedOptions[0];
    setValue(value);
    setSelectedValue(value);
    if (includeOther && onOtherSelected) {
      onOtherSelected(multiple ? selectedOptions.includes("other") : selectedOptions[0] === "other");
    }
  };

  const removeDuplicateOptions = (options: any) => {
    const uniqueOptions = new Map();
    if (options && options.length > 0) {
      options.forEach((option) => {
        if (option[valueField] !== "") {
          if (
            (name === "deliveryState" || name === "pickupState") &&
            option[valueField].includes("$")
          ) {
            // skip
          } else {
            uniqueOptions.set(option[valueField], option);
          }
        }
      });
    }
    return Array.from(uniqueOptions.values());
  };

  const filteredOptions = removeDuplicateOptions(options);
  const dynamicClassName =
    !multiple && selectedValue === "other" ? "col-sm-1" : className;

  return (
    <>
      <label htmlFor={name} className={`control-label ${labelClassName} fw-norm p-lr5`}>
        {label}
        {required && (
          <>
            &nbsp;<span style={{ color: "red" }}>#</span>
          </>
        )}
      </label>
      <div className={dynamicClassName}>
        <select
          {...field}
          id={name}
          className={`form-control ${meta.touched && meta.error ? "is-invalid" : ""}`}
          onChange={handleSelectChange}
          disabled={readOnly}
          multiple={multiple}
          value={selectedValue}
        >
          {!skipChoose && <option value="">Choose...</option>}
          {includeOther && <option value={-2}>Other</option>}
          {filteredOptions && filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <option key={option[valueField]} value={option[valueField]}>
                {option[labelField]}
              </option>
            ))
          ) : (
            <option value="">No options available</option>
          )}
        </select>
        {meta.touched && meta.error && (
          <div className="help-block error-msg">{meta.error}</div>
        )}
      </div>
    </>
  );
};

export default SelectField;