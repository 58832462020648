import React from 'react';
import { Calendar } from 'primereact/calendar';
import { useField, useFormikContext } from 'formik';

interface DatePickerFieldProps {
  label: string;
  name: string;
  dateFormat?: string;
  className?: string;
  labelClassName?: string;
  readOnly?: boolean;
  labelIn?:boolean
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({ label, name, dateFormat = "mm/dd/yy",className = 'col-sm-3',labelClassName = 'col-sm-1',readOnly = false,labelIn=false  }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const handleChange = (e: any) => {
    setFieldValue(name, e.value);
  };

  const dateValue = field.value ? new Date(field.value) : null;
  const labelToShow=<label htmlFor={name} className={`control-label ${labelClassName} fw-norm p-lr5`}>{label}</label>;
  return (
    <>
     {!labelIn&&labelToShow}
      <div className={className}>
        {labelIn&&labelToShow}
        <Calendar
          {...field}
          id={name}
          dateFormat={dateFormat}
          className={` ${meta.touched && meta.error ? 'is-invalid' : ''}`}
          value={dateValue}
          onChange={handleChange}
          showIcon
          disabled={readOnly}
        />
        {meta.touched && meta.error && (
          <span className="help-block error-msg">{meta.error}</span>
        )}
      </div>
    </>
  );
};

export default DatePickerField;
