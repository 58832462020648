import React from 'react';



interface VehicleInformationDetailProps {
  data: any;
}

const VehicleInformationDetail: React.FC<VehicleInformationDetailProps> = ({ data }) => {


  return (
    <div id="vehicleInfoId" className="form-horizontal white content-border padding15">
      <fieldset>
        <legend>Vehicle Information</legend>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Vehicle #:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.vehicleid}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Vehicle Type:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.vehicleType}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Vehicle Style:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.vehicleStyle}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Beg. Miles:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.beginningMiles}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">End Miles:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.endingMiles}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Trip Miles:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.tripMiles}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Trailer #:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.trailerNumber}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Trailer Style:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.trailerStyle}</p>
          </div>
        </div>
        
        
      </fieldset>
    </div>
  );
};

export default VehicleInformationDetail;
