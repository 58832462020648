import React from 'react';
import InputField from './InputField';
import SelectField from './SelectField';
import TextAreaField from './TextAreaField';
import TimeField from './TimeField';
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { useAuth } from "../utils/auth";
import { ROLES } from "../constants/roles";

interface VehicleInformationProps {
  readOnly: boolean; 
}


const VehicleInformation: React.FC<VehicleInformationProps> = ({readOnly=false}) => {


  const { user } = useAuth();
  const userRole = user?.role;
  const { vehiclesList:vehicles,
   driversList:drivers,
   trailerList:trailers,
   cityList:cities
     } = useSelector(
    (state: RootState) => state.formData
  );
  const { values } = useFormikContext<any>();

  const showOtherDriver = String(values.driverId) === '-2';
  return (
    <div className="col-md-12 form-horizontal white content-border padding15" id="divVehicleInfo">
      <fieldset>
        <legend>Vehicle Information</legend>
        <div className="form-group">
          <SelectField label="Vehicle #" name="vehicleId" options={vehicles} valueField='id' labelField='value' readOnly={readOnly}   className = "col-sm-2" labelClassName = "col-sm-1" />
          <label className="control-label col-sm-1 fw-norm p-lr5">Dispatcher</label>
          <div className="col-sm-2">{values.dispatcherName}</div>
          <SelectField label="Driver" name="driverId" options={drivers} valueField='driverId' labelField='fullName' className={showOtherDriver ? 'col-sm-1' : 'col-sm-2'} includeOther={userRole===ROLES.REQUESTOR?false:true} readOnly={readOnly} />
          {showOtherDriver &&  <InputField name="otherDriver" className='col-sm-1' label="" readOnly={readOnly} />}
          <SelectField label="Trailer" name="trailerid" className = "col-sm-2" options={trailers}  valueField='id' labelField='value' readOnly={readOnly}   />
        </div>
        <div className="form-group">
          <SelectField label="Consolidations" name="consolidationsId" className = "col-sm-2"  options={[{ value: "1", label: "Yes" }, { value: "2", label: "No" }]} readOnly={readOnly} />
          <InputField label="No. of Consolidations" className = "col-sm-2"  name="numberOfConsolidations" type="text" readOnly={readOnly}  />
          <InputField label="Final Miles" name="finalMiles" className = "col-sm-2"  type="text" readOnly={readOnly} numeric />
        </div>
        <div className="form-group">
          <SelectField label="Starting Point" className = "col-sm-2"  name="startingPointId" options={cities}   valueField='cityId' labelField='cityName'  readOnly={readOnly} />
          <SelectField label="End Point" name="endPointId" className = "col-sm-2"  options={cities} valueField='cityId' labelField='cityName' readOnly={readOnly} />
          <InputField label="Mileage at P/U" name="mileageAtPU" type="text" className = "col-sm-2"  readOnly={readOnly} numeric />
          <InputField label="Mileage at Delivery Location" name="mileageAtDeliveryLocation" className = "col-sm-2" labelClassName='col-sm-1' type="text" readOnly={readOnly} numeric  />
        </div>
        <div className="form-group">
          <InputField label="Starting Mileage" name="startingMileage" className = "col-sm-2" type="text" readOnly={readOnly} numeric  />
          <InputField label="Ending Mileage" name="endingMiles" type="text" className = "col-sm-2" readOnly={readOnly} numeric />
          <TimeField label="Time at P/U" name="timeAtPU" className='col-sm-2' labelClassName='col-sm-1'  readOnly={readOnly}  />
          <TimeField label="Time at Delivery Location" name="timeAtDeliveryLocation" className='col-sm-2' labelClassName='col-sm-1' readOnly={readOnly}  />
        </div>
        <div className="form-group">
        <TimeField label="Starting Time" name="startTime" className='col-sm-2' labelClassName='col-sm-1'   readOnly={readOnly}  />
          <TimeField label="End Time" name="endTime" className='col-sm-2' labelClassName='col-sm-1' readOnly={readOnly}  />
          <InputField label="Wait Time at P/U" className='col-sm-2' name="waitTimeAtPU" type="text"  numeric readOnly={readOnly}   />
          <InputField label="Wait Time at Delivery Location" className='col-sm-2' name="waitTimeAtDelivery" numeric type="text"  readOnly={readOnly}   />         
        </div>
        <div className="form-group">
        <TextAreaField label="Vehicle Inspection Comments" name="vehicleInspectionComments" rows={8} readOnly={readOnly} maxlength={1000} />
          <TextAreaField label="Problem during this run" name="problems" rows={8} readOnly={readOnly} maxlength={1000}  />
        </div>
      </fieldset>
    </div>
  );
};

export default VehicleInformation;
