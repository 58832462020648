import React from 'react';

interface DriverTravelInformationDetailProps {
  data: any;
}

const DriverTravelInformationDetail: React.FC<DriverTravelInformationDetailProps> = ({ data }) => {

  return (
    <div id="travelInfoId" className="form-horizontal white content-border padding15">
      <fieldset>
        <legend>Driver Travel Information</legend>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Driver:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.drivername}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Driver Type:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.drivertype}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Dispatcher:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.destCountry}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Dep. Date:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.departureDate}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Dep. Time</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.departureTime}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Return Date:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.returnDate}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Return Time:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.returnTime}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Time Waited:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.timewaited}</p>
          </div>
        </div>

      </fieldset>
    </div>
  );
};

export default DriverTravelInformationDetail;
