import React from 'react';
import { Dialog } from 'primereact/dialog';

interface CommonModalProps {
  visible: boolean;
  header: string;
  content: React.ReactNode;
  onClose: () => void;
  onConfirm?: () => void;
  showCloseButton?: boolean;
  showConfirmButton?: boolean;
  confirmButtonText?: string;
}

const CommonModal: React.FC<CommonModalProps> = ({
  visible,
  header,
  content,
  onClose,
  onConfirm,
  showCloseButton = true,
  showConfirmButton = false,
  confirmButtonText = 'Confirm'
}) => {
  return (
    <Dialog header={header} visible={visible}  onHide={onClose}>
      {content}
      <div style={{ marginTop: '20px', textAlign: 'right' }}>
        {showConfirmButton && (
          <button className="btn btn-primary" onClick={onConfirm} style={{ marginRight: '10px' }}>
            {confirmButtonText}
          </button>
        )}
        {showCloseButton && (
          <button className="btn btn-secondary" onClick={onClose}>
            Close
          </button>
        )}
      </div>
    </Dialog>
  );
};

export default CommonModal;
