import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import axiosInstance, { updateBaseUrl } from "../api/axiosInstance";
import { ENDPOINTS } from '../api/config';
import { store } from '../store';
import { showNotification } from '../features/notification/notificationSlice';
import{MAX_FILE_SIZE, MAX_FILES, ALLOWED_FILE_TYPES} from "../constants/global"

interface AttachmentsnProps {
  readOnly: boolean; 
}

const Attachments: React.FC<AttachmentsnProps> = ({readOnly=false}) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [removedFiles, setRemovedFiles] = useState<any[]>([]);
  const [existingFiles, setExistingFiles] = useState<any[]>([]);

  useEffect(() => {
    if (Array.isArray(values.attachments)) {
      setExistingFiles(values.attachments);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      const filteredFiles = newFiles.filter(file => {
        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
          store.dispatch(showNotification({ message: `File type not allowed: ${file.name}`, type: 'error' }));
          return false;
        }
        if (file.size > MAX_FILE_SIZE) {
          store.dispatch(showNotification({ message:`File size exceeds limit (1 MB): ${file.name}`, type: 'error' }));
          return false;
        }
        return true;
      });

      const updatedFiles = [...uploadedFiles, ...filteredFiles];
      if (updatedFiles.length > MAX_FILES) {
        store.dispatch(showNotification({ message:`Maximum ${MAX_FILES} files can be uploaded.`, type: 'error' }));
        return;
      }
      setUploadedFiles(updatedFiles);
      const filesWithMetadata = updatedFiles.map(file => ({
        name: file.name,
        OriginalFileName: file.name,
        addedDate: new Date().toLocaleDateString(),
        file: file
      }));
      
  
      setFieldValue('attachments.add', filesWithMetadata);
    }
  };

  const handleDeleteFile = (index: number, isExisting: boolean) => {
    if(readOnly)
      return false;
    if (isExisting) {
      const updatedExistingFiles = existingFiles.filter((_, i) =>  i !== index);
      setExistingFiles(updatedExistingFiles);
      setRemovedFiles([...removedFiles, existingFiles[index]]);
      setFieldValue('attachments.remove', [...removedFiles, existingFiles[index]]);
    } else {
      const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
      setUploadedFiles(updatedFiles);
      const filesWithMetadata = updatedFiles.map(file => ({
        name: file.name,
        OriginalFileName: file.name,
        addedDate: new Date().toLocaleDateString(),
        file: file
      }));
      setFieldValue('attachments.add', filesWithMetadata); // Update Formik state
    }
  };

  const downloadFile = async (filename: string, originalFilename: string) => {
    try {
      updateBaseUrl(0);
      const response = await axiosInstance.get(`${ENDPOINTS.DOWNLOAD_ATTACHMENT}`, {
        params: { fileName: filename },

      });

      const base64Data = response.data.data;
      const binaryString = atob(base64Data);
      const binaryLength = binaryString.length;
      const bytes = new Uint8Array(binaryLength);
      for (let i = 0; i < binaryLength; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
  
      const blob = new Blob([bytes], { type: response.data.mimeType });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', originalFilename);
  
      document.body.append(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const fileDownloadLink = (rowData: any) => {
    return rowData.guidFileName ? (
      <a href="#!" onClick={() => downloadFile(rowData.guidFileName,rowData.OriginalFileName)}>{rowData.OriginalFileName}</a>
    ) : (
      <span>{rowData.OriginalFileName??rowData.name}</span>
    );
  };

  return (
    <div className="col-md-12 form-horizontal white content-border padding15" id="divVehicleInfo">
      <fieldset>
        <legend>
          Attachments
          <label className="input-group-btn" style={{ float: 'right', marginRight: '170px' }}>
            <span className="btn btn-primary icn-add pad32">
              Add New Attachments
              <input
                id="fileUpload"
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                multiple
                disabled={readOnly}
                accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.gif"
              />
            </span>
          </label>
          <span style={{ float: 'right', marginRight: '10px', fontSize: '13px', color: 'red', textAlign: 'right' }} id="spnAttch">
            Allowed file types: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .gif
          </span>
        </legend>
        <div className="form-group">
          <div className="scroll_treq" style={{ padding: '15px' }}>
            <DataTable value={[...existingFiles, ...uploadedFiles]} paginator rows={10} className="p-datatable-gridlines">
              <Column header="Actions" body={(rowData, { rowIndex }) => (
                <Button
                  icon="pi pi-trash"
                  className="p-button-danger"
                  type='button'
                  onClick={() => handleDeleteFile(rowIndex, !!rowData.attachmentId)}
                  disabled={readOnly}
                />
              )} />
              <Column field="OriginalFileName" header="Name" sortable body={fileDownloadLink} />
              <Column field="addedDate" header="Added Date" sortable body={() => new Date().toLocaleDateString()} />
            </DataTable>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default Attachments;
