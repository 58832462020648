import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 p-lr0">
            <div className="col-xs-10 copyright-txt">
              Copyright © 2021 Oceaneering International, Inc
            </div>
            <div className="col-xs-2 version-txt">v1.1</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
