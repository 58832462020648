import { createSlice } from '@reduxjs/toolkit';
import createDataFetchThunk from '../api/createDataFetchThunk';
import { submitFormData,submitAttachmentData } from '../api/formService';

export const submitForm = createDataFetchThunk<any, any>(
  'form/submitForm',
  async (formData: any) => {
    const response = await submitFormData(formData);
    return response;
  }
);

export const submitAttachments = createDataFetchThunk<any, { treqId: string; formData: FormData }>(
  'form/submitAttachments',
  async ({ treqId, formData }) => {

    const response = await submitAttachmentData(treqId, formData);
    return response;
  }
);
const formSubmissionSlice = createSlice({
  name: 'formSubmission',
  initialState: {
    loading: false,
    loadingAttachments: false,
    loadingSuccess: false,
    success: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitForm.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(submitForm.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(submitForm.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload as string;
      })
      .addCase(submitAttachments.pending, (state) => {
        state.loadingAttachments = true;
        state.loadingSuccess = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(submitAttachments.fulfilled, (state) => {
        state.loadingAttachments = false;
        state.loadingSuccess = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(submitAttachments.rejected, (state, action) => {
        state.loadingAttachments = false;
        state.loading = false;
        state.loadingSuccess = false;
        state.error = action.payload as string;
      });
  },
});

export default formSubmissionSlice.reducer;