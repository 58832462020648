import React from 'react';

interface TravelInformationDetailProps {
  data: any;
}

const TravelInformationDetail: React.FC<TravelInformationDetailProps> = ({ data }) => {

  return (
    <div id="travelInfoId" className="form-horizontal white content-border padding15">
      <fieldset>
        <legend>Destination Information</legend>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Est. Dep. Date Time:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.estdeparturetime}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Est. Arr. Date Time:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.estarrivaltime}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Dest. Country:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.destCountry}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Dest. State:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.deliveryStateId}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Dest. City</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.destCityname}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-3 fw-norm p-lr5">Dest. Site:</label>
          <div className="col-md-9">
            <p className="form-control-static">{data?.destSitename}</p>
          </div>
        </div>

      </fieldset>
    </div>
  );
};

export default TravelInformationDetail;
