import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";
import { ENDPOINTS } from "../../api/config";
import createDataFetchThunk from "../../api/createDataFetchThunk";
import {
    System,
    Project,
    Country,
    State,
    UDCType,
    MasterActivity,
    City,
    Destination,
    Driver,
    Trailer,
    Vehicle,
    Transport,
    Equipment,
    ApiResponse
} from "../../api/types";

async function fetchData<T>(url: string, params: any): Promise<T[]> {
  const response = await axiosInstance.get<ApiResponse<T>>(url, { params });
  return response.data.elements;
}

export const fetchSystem = createDataFetchThunk<System[]>(
  "fetchSystem",
  (params) =>
    axiosInstance
      .get<System[]>(`${ENDPOINTS.GET_SYSTEM_LIST}`)
      .then((res) => res.data)
);

export const fetchCity = createDataFetchThunk<City[]>(
  "fetchCity",
  (params) =>
    axiosInstance
      .get<City[]>(`${ENDPOINTS.GET_CITY_LIST}`)
      .then((res) => res.data)
);

export const fetchEquipment = createDataFetchThunk<Equipment[]>(
  "fetchEquipment",
  (params) =>
    axiosInstance
      .get<Equipment[]>(`${ENDPOINTS.GET_EQUIPMENT_LIST}`)
      .then((res) => res.data)
);

export const fetchDestination = createDataFetchThunk<Destination[]>(
  "fetchDestination",
  (params) =>
    axiosInstance
      .get<Destination[]>(`${ENDPOINTS.GET_DESTINATION_LIST}`)
      .then((res) => res.data)
);
export const fetchTransport = createDataFetchThunk<Transport[]>(
  "fetchTransport",
  (params) =>
    axiosInstance
      .get<Transport[]>(`${ENDPOINTS.GET_TRANSPORT_LIST}`)
      .then((res) => res.data)
);

export const fetchDrivers = createDataFetchThunk<Driver[]>(
  "fetchDrivers",
  (params) =>
    axiosInstance
      .get<Driver[]>(`${ENDPOINTS.GET_DRIVERS_LIST}`)
      .then((res) => res.data)
);

export const fetchVehicles = createDataFetchThunk<Vehicle[]>(
  "fetchVehicles",
  (params) =>
    axiosInstance
      .get<Vehicle[]>(`${ENDPOINTS.GET_VEHICLES_LIST}`)
      .then((res) => res.data)
);

export const fetchTrailers = createDataFetchThunk<Trailer[]>(
  "fetchTrailers",
  (params) =>
    axiosInstance
      .get<Trailer[]>(`${ENDPOINTS.GET_TRAILERS_LIST}`)
      .then((res) => res.data)
);

export const fetchCountries = createDataFetchThunk<Country[]>(
  "fetchCountries",
  async (params) => {
    return fetchData<Country>(`${ENDPOINTS.GET_COUNTRY_LIST}`, params);
  },
  1
);

export const fetchStates = createDataFetchThunk<State[]>(
  "fetchStates",
  async (params) => {
    return fetchData<State>(`${ENDPOINTS.GET_STATE_LIST}`, params);
  },
  1
);

export const fetchLocations = createDataFetchThunk<State[], { $filter: string }>(
  "fetchLocations",
  async (params) => {
    return fetchData<State>(`${ENDPOINTS.GET_LOCATION_LIST}`, params);
  },
  1
);

export const fetchUdcType = createDataFetchThunk<UDCType[]>(
  "fetchUdcType",
  async (params) => {
    return fetchData<UDCType>(`${ENDPOINTS.GET_UDC_TYPE}`, params);
  },
  1
);

export const fetchActivities = createDataFetchThunk<MasterActivity[], { $filter: string }>(
  "fetchActivities",
  async (params) => {
    return fetchData<MasterActivity>(`${ENDPOINTS.GET_MASTER_ACTIVITY}`, params);
  },
  1
);

export const fetchProjects = createDataFetchThunk<Project[], { $select: string,$filter:string }>(
  "fetchProjects",
  async (params) => {
    return fetchData<Project>(`${ENDPOINTS.GET_PROJECT_LIST}`, params);
  },
  1
);

export const fetchAllData = createAsyncThunk(
  'data/fetchAll',
  async (_, { dispatch }) => {
    const requests = [
      //dispatch(fetchSystem()),
      //dispatch(fetchCountries()),
      // dispatch(fetchEquipment()),
      //dispatch(fetchTransport()),
      // dispatch(fetchLocations({$filter:"status='A'"})),
      dispatch(fetchCity()),
      dispatch(fetchDrivers()),
      dispatch(fetchVehicles()),
      dispatch(fetchTrailers()),
      dispatch(fetchStates()),
      dispatch(fetchUdcType()),
      dispatch(fetchDestination()),
    ];

    await Promise.all(requests);
  }
);

export const fetchAllDataBrasil = createAsyncThunk(
  'data/fetchAllBrasil',
  async (_, { dispatch }) => {
    const requests = [
      dispatch(fetchEquipment()),
      dispatch(fetchTransport()),
      dispatch(fetchUdcType()),
    ];

    await Promise.all(requests);
  }
);


const initialState = {
  systemList: [] as System[],
  projectslist: [] as Project[],
  countryList: [] as Country[],
  stateList: [] as State[],
  udcType: [] as UDCType[],
  masterActivity: [] as MasterActivity[],
  cityList: [] as City[],
  destinationList: [] as Destination[],
  driversList: [] as Driver[],
  trailerList: [] as Trailer[],
  vehiclesList: [] as Vehicle[],
  transportList: [] as Transport[],
  equipmentList: [] as Equipment[],
  locations:[] as any,
  status: "idle",
  error: null as string | null,
};

const formDataSlice = createSlice({
  name: "formdata",
  initialState,
  reducers: {

    resetActivities: (state) => {
      state.masterActivity = [];
    },
    resetProjects: (state) => {
      state.projectslist = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystem.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.systemList = action.payload;
      })
      .addCase(fetchCity.fulfilled, (state, action) => {
        state.cityList = action.payload;
      })
      .addCase(fetchDrivers.fulfilled, (state, action) => {
        state.driversList = action.payload;
      })
      .addCase(fetchVehicles.fulfilled, (state, action) => {
        state.vehiclesList = action.payload;
      })
      .addCase(fetchTrailers.fulfilled, (state, action) => {
        state.trailerList = action.payload;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countryList = action.payload;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.stateList = action.payload;
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.locations = action.payload;
      })
      .addCase(fetchUdcType.fulfilled, (state, action) => {
        state.udcType = action.payload;
      })
      .addCase(fetchEquipment.fulfilled, (state, action) => {
        state.equipmentList = action.payload;
      })
      .addCase(fetchDestination.fulfilled, (state, action) => {
        state.destinationList = action.payload;
      })
      .addCase(fetchTransport.fulfilled, (state, action) => {
        state.transportList = action.payload;
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.masterActivity = action.payload;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projectslist = action.payload;
      });
  },
});
export const { resetActivities, resetProjects } = formDataSlice.actions;
export default formDataSlice.reducer;
