import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";
import { ENDPOINTS } from "../../api/config";
import createDataFetchThunk from "../../api/createDataFetchThunk";
import {
  DashboardDataItem,
  DriverData,
  MonthlyData,
  SummaryData,
  LastModifiedItem,
  ProjectType,
  DivisionType,
  DivisionProject,
  DataPerProject,
  DataSystemType,
  Project
} from "../../api/types";

interface YearAndDivision {
  year: string;
  division: string;
  projectId?:number | string;
}

interface SystemProject extends YearAndDivision {
  system?: string;
  project?: string;

}

export const fetchDivisions = createDataFetchThunk<DivisionType[]>(
  "dashboard/fetchDivisions",
  () =>  axiosInstance
  .get<DivisionType[]>(ENDPOINTS.GET_DIVISIONS)
  .then((res) => res.data)
);

export const fetchSystems = createDataFetchThunk<DataSystemType[]>(
  "dashboard/fetchSystems",
  () =>  axiosInstance
  .get<DataSystemType[]>(ENDPOINTS.GET_SYSTEM_LIST)
  .then((res) => res.data)
);

export const fetchProjects = createDataFetchThunk<Project[]>(
  "dashboard/fetchProjects",
  () =>  axiosInstance
  .get(ENDPOINTS.GET_PROJECT_LIST)
  .then((res) => res.data.elements as Project[]),
  1
);



export const fetchProjectsByYearAndDivision = createDataFetchThunk<ProjectType[], YearAndDivision>(
  "dashboard/fetchProjectsByYearAndDivision",
  (params) =>
    axiosInstance
      .get<ProjectType[]>(`${ENDPOINTS.GET_BRAZILIAN_PROJECT_TREQ}`,{
        params:{
          'year':params!.year,
          'divisioncode':params!.division,
          'projectId':params!.projectId
        }
      })
      .then((res) => res.data)
);



export const fetchDataPerProject = createDataFetchThunk<DataPerProject[], SystemProject>(
  "dashboard/fetchDataPerProject",
  (params) =>
    axiosInstance
      .get<DataPerProject[]>(`${ENDPOINTS.GET_BRAZILIAN_PROJECT_QUANTITY}`,{
        params:{
          'year':params!.year,
          'divisioncode':params!.division,
          'projectId':params!.projectId,
          'system':params!.system
        }
      })
      .then((res) => res.data)
);



export const fetchProjectsByDivision = createDataFetchThunk<ProjectType[], YearAndDivision>(
  "dashboard/fetchProjectsByDivision",
  (params) =>
    axiosInstance
      .get<ProjectType[]>(`${ENDPOINTS.GET_BRAZILIAN_PROJECT_DIVISION_TREQ}`,{
        params:{
          'year':params!.year,
          'divisioncode':params!.division,
        }
      })
      .then((res) => res.data)
);



export const fetchDashboardData1 = createDataFetchThunk<DashboardDataItem[]>(
  "dashboard/fetchData1",
  () =>
    axiosInstance
      .get<DashboardDataItem[]>(ENDPOINTS.GET_TREQ_STATUS)
      .then((res) => res.data)
);

export const fetchLastModifiedData = createDataFetchThunk<LastModifiedItem[]>(
  "dashboard/fetchLastModifiedData",
  () =>
    axiosInstance
      .get<LastModifiedItem[]>(ENDPOINTS.GET_LAST_MODIFIED)
      .then((res) => res.data)
);

export const fetchDashboardSummaryData = createDataFetchThunk<SummaryData[]>(
  "dashboard/fetchSummaryData",
  () =>
    axiosInstance
      .get<SummaryData[]>(ENDPOINTS.GET_TREQ_SUMMARY)
      .then((res) => res.data)
);

export const fetchDriverData = createDataFetchThunk<DriverData[], string>(
  "dashboard/fetchDriverData",
  (year) =>
    axiosInstance
      .get<DriverData[]>(`${ENDPOINTS.GET_DRIVERS_WITH_MAX_TREQ}?year=${year}`)
      .then((res) => res.data)
);

export const fetchMonthlyData = createDataFetchThunk<MonthlyData[], string>(
  "dashboard/fetchMonthlyData",
  (year) =>
    axiosInstance
      .get<MonthlyData[]>(`${ENDPOINTS.GET_MONTHLY_TREQ}?year=${year}`)
      .then((res) => res.data)
);

const initialState = {
  data1: [] as DashboardDataItem[],
  summaryData: [] as SummaryData[],
  driverData: [] as DriverData[],
  monthlyData: [] as MonthlyData[],
  lastModifiedData: [] as LastModifiedItem[],
  status: "idle",
  error: null as string | null,
  fetchProjectsByYearAndDivisionStatus:"idle",
  fetchDataPerProjectStatus:"idle", 
  fetchProjectsByDivisionStatus:"idle",
  fetchSummaryDataStatus: "idle",
  fetchSummaryDataError: null as string | null,
  fetchDriverDataStatus: "idle",
  fetchDriverDataError: null as string | null,
  fetchMonthlyDataStatus: "idle",
  fetchMonthlyDataError: null as string | null,
  fetchLastModifiedDataStatus: "idle",
  fetchLastModifiedDataError: null as string | null,
  divisions: [] as DivisionType[],
  projects: [] as ProjectType[],
  divisionData: [] as DivisionProject[],
  dataPerProject: [] as DataPerProject[],
  systems: [] as DataSystemType[],
  projectslist: [] as Project[],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add cases for fetchDashboardData1
    builder
      .addCase(fetchDashboardData1.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDashboardData1.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data1 = action.payload;
      })
      .addCase(fetchDashboardData1.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      // Add cases for fetchDashboardSummaryData
      .addCase(fetchDashboardSummaryData.pending, (state) => {
        state.fetchSummaryDataStatus = "loading";
      })
      .addCase(fetchDashboardSummaryData.fulfilled, (state, action) => {
        state.fetchSummaryDataStatus = "succeeded";
        state.summaryData = action.payload;
      })
      .addCase(fetchDashboardSummaryData.rejected, (state, action) => {
        state.fetchSummaryDataStatus = "failed";
        state.fetchSummaryDataError = action.payload as string;
      })
      // Add cases for fetchDriverData
      .addCase(fetchDriverData.pending, (state) => {
        state.fetchDriverDataStatus = "loading";
      })
      .addCase(fetchDriverData.fulfilled, (state, action) => {
        state.fetchDriverDataStatus = "succeeded";
        state.driverData = action.payload;
      })
      .addCase(fetchDriverData.rejected, (state, action) => {
        state.fetchDriverDataStatus = "failed";
        state.fetchDriverDataError = action.payload as string;
      })
      // Add cases for fetchMonthlyData
      .addCase(fetchMonthlyData.pending, (state) => {
        state.fetchMonthlyDataStatus = "loading";
      })
      .addCase(fetchMonthlyData.fulfilled, (state, action) => {
        state.fetchMonthlyDataStatus = "succeeded";
        state.monthlyData = action.payload;
      })
      .addCase(fetchMonthlyData.rejected, (state, action) => {
        state.fetchMonthlyDataStatus = "failed";
        state.fetchMonthlyDataError = action.payload as string;
      })
      // Add cases for fetchMonthlyData
      .addCase(fetchLastModifiedData.pending, (state) => {
        state.fetchLastModifiedDataStatus = "loading";
      })
      .addCase(fetchLastModifiedData.fulfilled, (state, action) => {
        state.fetchLastModifiedDataStatus = "succeeded";
        state.lastModifiedData = action.payload;
      })
      .addCase(fetchLastModifiedData.rejected, (state, action) => {
        state.fetchLastModifiedDataStatus = "failed";
        state.fetchLastModifiedDataError = action.payload as string;
      });
    builder.addCase(fetchDivisions.fulfilled, (state, action) => {
      state.divisions = action.payload;
    });
    builder.addCase(fetchSystems.fulfilled, (state, action) => {
      state.systems = action.payload;
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.projectslist = action.payload;
    });
    builder.addCase(fetchProjectsByYearAndDivision.pending, (state) => {
      state.fetchProjectsByYearAndDivisionStatus = "loading";
    })
    builder.addCase(
      fetchProjectsByYearAndDivision.fulfilled,
      (state, action) => {
        state.projects = action.payload;
        state.fetchProjectsByYearAndDivisionStatus="succeeded"
      }
    );
    builder.addCase(fetchProjectsByDivision.pending, (state) => {
      state.fetchProjectsByDivisionStatus = "loading";
    })
    builder.addCase(
      fetchProjectsByDivision.fulfilled,
      (state, action) => {
        state.divisionData = action.payload;
        state.fetchProjectsByDivisionStatus = "succeeded";
      }
    );
    builder.addCase(fetchDataPerProject.pending, (state) => {
      state.fetchDataPerProjectStatus  = "loading";
    })
    builder.addCase(
      fetchDataPerProject.fulfilled,
      (state, action) => {
        state.dataPerProject = action.payload;
        state.fetchDataPerProjectStatus = "succeeded";
      }
    );
  },
});

export default dashboardSlice.reducer;