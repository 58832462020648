import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RootState, AppDispatch } from '../store';
import axiosInstance from '../api/axiosInstance';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { ENDPOINTS } from '../api/config';
import { formatDateAPI } from '../utils/common';
import CommonModal from './CommonModal';

interface TreqTableProps {
  fetchData: (params: any) => void;
  selector: (state: RootState) => any;
  showPrintButton?: boolean;
  isOldTreq?: boolean;
  filterParams: { startDate: string; endDate: string; treqId: string };
}

const sortFieldIndexMap = {
  isOldTreq: {
    requestid: 1,
    Requestor: 2,
    project: 3,
    dateneeded: 4,
    IsCurrent: 5,
  },
  default: {
    IsUploadedToLogistic: 1,
    requestid: 2,
    Requestor: 3,
    SubmittedDate: 4,
    drivername: 5,
    project: 6,
    DateNeededString:7,
    timeneeded: 8,
    PUCity: 9,
    Location:10,
    DeliveryCity: 11,
    DeliveryLocation: 12,
    NameOfPULocationContact: 13,
    dispatcherName: 14,
    statusId: 15,
  },
};

const TreqTable: React.FC<TreqTableProps> = ({ fetchData, selector, showPrintButton = false, isOldTreq = false, filterParams }) => {
  const sortFieldIndex = isOldTreq ? sortFieldIndexMap.isOldTreq : sortFieldIndexMap.default;
  const reverseSortFieldIndexMap = Object.fromEntries(Object.entries(sortFieldIndex).map(([key, value]) => [value, key]));
  const [localSearchValues, setLocalSearchValues] = useState<{ [key: string]: string }>({});

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    sortField:isOldTreq?1:2, // default sort column
    sortOrder: -1, // default sort order
    fromScheduleLink: false,
    searchField: '',
    searchValue: '',
  });

  const [modal, setModal] = useState({
    isVisible: false,
    header: '',
    content: '',
    onConfirm: () => {},
    showConfirmButton: false,
  });

  const dispatch = useDispatch<AppDispatch>();
  const { data, loading, totalRecords } = useSelector(selector);

  useEffect(() => {
    const { first, rows, sortField, sortOrder, searchField, searchValue } = lazyState;
    const page = first / rows + 1;
    dispatch(fetchData({
      fromDate: formatDateAPI(filterParams.startDate),
      toDate: formatDateAPI(filterParams.endDate),
      treqId: filterParams.treqId,
      page,
      size: rows,
      sortField,
      sortOrder,
      fromScheduleLink: false,
      searchField,
      searchValue,
    }));
  }, [dispatch, lazyState, fetchData, filterParams]);

  const onPage = useCallback((event: any) => {
    setLazyState((prevState) => ({
      ...prevState,
      first: event.first,
      rows: event.rows,
    }));
  }, []);

  const onSort = useCallback((event: any) => {
    setLazyState((prevState) => ({
      ...prevState,
      sortField: sortFieldIndex[event.sortField] || '',
      sortOrder: event.sortOrder,
    }));
  }, [sortFieldIndex]);
  

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnFilterInputChange = useCallback(debounce((field: string, value: string) => {
    setLazyState((prevState) => ({
      ...prevState,
      searchField: sortFieldIndex[field] || '',
      searchValue: value,
    }));
  }, 1500), []);

  const onFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const value = e.target.value;
    setLocalSearchValues(prevValues => ({
      ...prevValues,
      [field]: value
    }));
    debouncedOnFilterInputChange(field, value);
  };

  const onSelectionChange = (event: any) => {
    setSelectedRows(event.value);
  };

  const handlePrint = () => {
    if (selectedRows.length > 0) {
      setModal({
        isVisible: true,
        header: 'Confirmation Message',
        content: 'Are you sure you want to print selected T-req?',
        onConfirm: async () => {
          try {
            const requestIds = selectedRows.map((row: any) => row.requestid);
            const response = await axiosInstance.post(
              `${ENDPOINTS.GET_LIST_EXPORT}`,
              requestIds,
              { responseType: 'blob' }
            );
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(pdfBlob);
            const tempLink = document.createElement('a');
            tempLink.href = url;
            tempLink.setAttribute('download', 'Multiple_Transportation_Request_Print.pdf');

            document.body.appendChild(tempLink);
            tempLink.click();

            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error('Failed to download PDF:', error);
          }
          setModal((prevModal) => ({ ...prevModal, isVisible: false }));
        },
        showConfirmButton: true,
      });
    } else {
      setModal({
        isVisible: true,
        header: 'Error Alert',
        content: 'Please select at least one treq to print',
        onConfirm: () => setModal((prevModal) => ({ ...prevModal, isVisible: false })),
        showConfirmButton: false,
      });
    }
  };

  const renderTreqID = useCallback((rowData: any) => (
    <Link to={isOldTreq ? `/treq/detail/${rowData.requestid}` : `/treq/edit/${encodeURIComponent(rowData.EncryptedRequestID||rowData.requestid)}`}>
      {rowData.requestid}
    </Link>
  ), [isOldTreq]);


  //const renderDate = useCallback((rowData: any) => formatDateAPI(rowData.SubmittedDate), []);

  

  const renderDateNeeded = useCallback((rowData: any) => formatDateAPI(rowData.dateneeded), []);

  const renderScanPay = useCallback((rowData: any) => (rowData.IsUploadedToLogistic ? 'Yes' : 'No'), []);

  const renderStatus = useCallback((rowData: any) => {
    const statusLabels: { [key: number]: string } = {
      1: 'Draft',
      2: 'Submitted',
      3: 'Accepted',
      4: 'Closed',
      5: 'Confirmed',
    };
    return statusLabels[rowData.statusId] || rowData.statusId;
  }, []);

  const columns = isOldTreq
    ? [
      { field: 'requestid', header: 'T-Req. ID', body: renderTreqID },
      { field: 'Requestor', header: 'Requestor' },
      { field: 'project', header: 'Project' },
      { field: 'dateneeded', header: 'Date Needed', body: renderDateNeeded },
      { field: 'IsCurrent', header: 'Is Current' },
    ]
    : [
      { field: 'IsUploadedToLogistic', header: 'Uploaded to Pay & code', body: renderScanPay, filter: false },
      { field: 'requestid', header: 'T-Req. ID', body: renderTreqID },
      { field: 'Requestor', header: 'Requestor' },
      { field: 'SubmittedDate', header: 'Date Submitted' },
      { field: 'drivername', header: 'Driver' },
      { field: 'project', header: 'Job & Project' },
      { field: 'DateNeededString', header: 'Date Needed' },
      { field: 'timeneeded', header: 'Time Needed' },
      { field: 'PUCity', header: 'Pickup City' },
      { field: 'Location', header: 'Location' },
      { field: 'DeliveryCity', header: 'Delivery City' },
      { field: 'DeliveryLocation', header: 'Delivery Location' },
      { field: 'NameOfPULocationContact', header: 'Contact Name' },
      { field: 'dispatcherName', header: 'Dispatcher' },
      { field: 'statusId', header: 'Status', body: renderStatus },
    ];

  return (
    <div className="col-md-12 tiq-grid treq-table">
      {showPrintButton &&
        <div className="row">
          <div className="col-sm-6"></div>
          <div className="col-sm-6 treqTable">
            <button onClick={handlePrint} className="btn icn-print" type="button" id="btnPrint">Multi Print</button>
          </div>
        </div>
      }
      <DataTable
        value={data}
        lazy
        filterDisplay="row"
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        first={lazyState.first}
        rows={lazyState.rows}
        totalRecords={totalRecords}
        rowsPerPageOptions={[10, 20, 50, 100]}
        onPage={onPage}
        onSort={onSort}
        sortField={reverseSortFieldIndexMap[lazyState.sortField]}
        sortOrder={lazyState.sortOrder}
        loading={loading}
        selection={selectedRows}
        onSelectionChange={onSelectionChange}
        dataKey="requestid"
        className="table table-striped table-bordered table-hover dataTable"
      >
        {showPrintButton && <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />}
        {columns.map((col) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={col.body}
            sortable
            filter={col.filter !== false}
            filterElement={
              col.filter !== false && (
                <input
                  type="text"
                  className="p-inputtext p-component p-column-filter"
                  value={localSearchValues[col.field] || ''}
                  onChange={(e) => onFilterInputChange(e, col.field)}
                  placeholder="Search"
                />
              )
            }
          />
        ))}
      </DataTable>
      <CommonModal
        visible={modal.isVisible}
        header={modal.header}
        content={modal.content}
        onClose={() => setModal((prevModal) => ({ ...prevModal, isVisible: false }))}
        showConfirmButton={modal.showConfirmButton}
        onConfirm={modal.onConfirm}
      />
    </div>
  );
};

export default TreqTable;
