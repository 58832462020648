import React, { useState, useEffect,useCallback,useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDriverData } from '../../features/dashboard/dashboardSlice'; 
import { RootState, AppDispatch } from '../../store';
import GenericChartComponent from '../../components/GenericChartComponent';
import SelectComponent from '../../components/SelectComponent';
import { yearOptions } from '../../utils/date';
import Loading from "../../components/Loading"

const DriverChart = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { driverData,fetchDriverDataStatus } = useSelector((state: RootState) => state.dashboard);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());

  useEffect(() => {
     dispatch(fetchDriverData(selectedYear));
  }, [dispatch, selectedYear]);

  const handleYearChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(event.target.value);
}, []);


const chartData = useMemo(() => {
  const validData = Array.isArray(driverData) ? driverData : [];
  return {
    labels: validData.map(data => data.driverName),
    datasets: [{
      label: 'Treq Count',
      data: validData.map(data => data.count),
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgb(54, 162, 235)',
      borderWidth: 1,
    }]
  };
}, [driverData]);




const chartOptions = useMemo(() => ({
  scales: {
      y: {
          beginAtZero: true
      }
  },
  responsive: true
}), []);

  return (
    <div>
      <h2>Drivers with maximum Treq's</h2>
      <div className="col-sm-3" style={{ float: 'right' }}>
      <SelectComponent value={selectedYear} onChange={handleYearChange} options={yearOptions} title="Year" />
      </div>
      {fetchDriverDataStatus==='loading'?<Loading style={{height:'40vh',width:'100%'}} />:
      <GenericChartComponent type="bar" data={chartData} options={chartOptions} />}
    </div>
  );
};

export default DriverChart;
