import React, { useState, useCallback } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ROLES } from '../constants/roles';
import { useAuth } from '../utils/auth';

const MainNavigation: React.FC = () => {

  const { user } = useAuth();
  const location = useLocation();
  const userRole = user?.role as string;

  const [dropdownsOpen, setDropdownsOpen] = useState<{ [key: string]: boolean }>({
    reports: false,
    admin: false,
  });

  const toggleDropdown = useCallback((dropdown: string) => {
    setDropdownsOpen(prevState => ({ ...prevState, [dropdown]: !prevState[dropdown] }));
  }, []);

  const handleMouseEnter = useCallback((dropdown: string) => {
    setDropdownsOpen(prevState => ({ ...prevState, [dropdown]: true }));
  }, []);

  const handleMouseLeave = useCallback((dropdown: string) => {
    setDropdownsOpen(prevState => ({ ...prevState, [dropdown]: false }));
  }, []);

  const isActiveLink = (path: string): boolean => {
    return location.pathname === path;
  };

  const renderTReqNavigation = () => {
    if (user?.SchedulinkInfo) {
      if (user.SchedulinkInfo.treqId === 0) {
        return <li className={`main ${isActiveLink('/create-treq') ? 'active' : ''}`}>
          <NavLink to="/create-treq">Create T-Req</NavLink>
        </li>;
      } else {
        return <li className={`main ${isActiveLink(`/treq/edit/${user.SchedulinkInfo.treqId}`) ? 'active' : ''}`}>
          <NavLink to={`/treq/edit/${user.SchedulinkInfo.treqId}`}>Edit T-Req</NavLink>
        </li>;
      }
    }
    return null; // Return null if there is no SchedulinkInfo
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <nav className="navbar navbar-default">
          <div id="navbarCollapse" className="collapse navbar-collapse">
            <ul className="nav navbar-nav">
              {renderTReqNavigation()||
              <>
              <li className={`main ${isActiveLink('/dashboard') ? 'active' : ''}`}>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </li>
              {[ROLES.APPLICATION_ADMIN, ROLES.STAFF_ADMIN, ROLES.DISPATCHER, ROLES.REQUESTOR].includes(userRole) && (
                <li className={`main ${isActiveLink('/treq-listing') ? 'active' : ''}`}>
                  <NavLink to="/treq-listing">Treq-Listing</NavLink>
                </li>
              )}
               {[ROLES.APPLICATION_ADMIN, ROLES.STAFF_ADMIN, ROLES.REQUESTOR].includes(userRole) && (
              <li className={`main ${isActiveLink('/create-treq') ? 'active' : ''}`}>
                  <NavLink to="/create-treq">Create T-Req</NavLink>
                </li>
                 )}
              {(userRole === ROLES.APPLICATION_ADMIN || userRole === ROLES.STAFF_ADMIN) && (
                <>
                <li className={`dropdown ${isActiveLink('/reports') ? 'active' : ''} ${dropdownsOpen.reports ? 'open' : ''}`}
                  onMouseEnter={() => handleMouseEnter('reports')}
                  onMouseLeave={() => handleMouseLeave('reports')}
                  onClick={() => toggleDropdown('reports')}>
                <a className="dropdown-toggle" role="button" href="#reports">
                  Reports <span className="caret"></span>
                </a>
                <ul className="dropdown-menu drop-menu-bg">
                  <li><NavLink to="/reports/monthly">Monthly</NavLink></li>
                  <li><NavLink to="/reports/detail">Detail</NavLink></li>
                </ul>
              </li>
              </>
               )}
                {(userRole === ROLES.APPLICATION_ADMIN) && (
                <li className={`dropdown ${isActiveLink('/admin') ? 'active' : ''} ${dropdownsOpen.admin ? 'open' : ''}`}
                    onMouseEnter={() => handleMouseEnter('admin')}
                    onMouseLeave={() => handleMouseLeave('admin')}
                    onClick={() => toggleDropdown('admin')}>
                  <a className="dropdown-toggle" role="button" href="#admin">
                    Admin <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu drop-menu-bg">
                    <li><NavLink to="/admin/add-user">Add User</NavLink></li>
                    <li><NavLink to="/admin/manage-user">Manage User</NavLink></li>
                    <li><NavLink to="/admin/manage-groups">Manage Groups</NavLink></li>
                  </ul>
                </li>
                 )}
                
             
              {(userRole === ROLES.APPLICATION_ADMIN || userRole === ROLES.STAFF_ADMIN || userRole === ROLES.REQUESTOR)  && (
                <li className={`main ${isActiveLink('/old-treq-listing') ? 'active' : ''}`}>
                  <NavLink to="/old-treq-listing">Old T-Req Listing</NavLink>
                </li>
              )}
              </>}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MainNavigation;