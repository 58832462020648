import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import createDataFetchThunk from "../../api/createDataFetchThunk";
import { RootState } from '../../store';
import axiosInstance from "../../api/axiosInstance";
import {showNotification} from "../notification/notificationSlice";
import {ENDPOINTS} from "../../api/config";

interface Role {
  roleName: string;
  id: string;
}


export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roleName: string;
  status: string;
  username?: string;
  employeeId?: string;
  isEnabled?: boolean;
  delete?: boolean;
}

interface UserState {
  loading: boolean;
  roles: Role[];
  users: User[];
  error: string | null;
}



const initialState: UserState = {
  loading: false,
  roles: [],
  users: [],
  error: null,
};

export const fetchRoles = createDataFetchThunk<Role[], undefined>(
  'user/fetchRoles',
  async () => {
    const response = await axiosInstance.get(`${ENDPOINTS.GET_ROLES}`);
    return response.data;
  }
);

export const fetchUsers = createDataFetchThunk<User[], number>(
  'user/fetchUsers',
  async (userType) => {
    const response = await axiosInstance.get(`${ENDPOINTS.GET_USERS}?userType=${userType}`);
    return response.data;
  },
  2
);

export const updateUser = createDataFetchThunk<void, { userData: any; headers: any }>(
  'user/updateUser',
  async ({ userData, headers }) => {
    await axiosInstance.post(ENDPOINTS.SAVE_USER, userData, { headers });
  },
  2
);

export const registerUser = createDataFetchThunk<any, { userData: any, headers: any }>(
  'user/register',
  async ({ userData, headers }) => {
    try {
      const response = await axiosInstance.post(ENDPOINTS.REGISTER_USER, userData, { headers });
      return response.data;
    } catch (error) {
      throw new Error('Failed to register: ' + error.response?.data || error.message);
    }
  },
  2
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action: PayloadAction<Role[]>) => {
        state.roles = action.payload;
        state.loading = false;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch roles';
        state.loading = false;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false;
        showNotification({ message: 'User added successfully', type: 'success' });
      })
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.error = action.payload as string;
        state.users =[];
        state.loading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        showNotification({ message: 'User updated successfully', type: 'success' });
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        showNotification({ message: action.payload as string, type: 'error' });
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to register user';
      });
  },
});

export const selectRoles = (state: RootState) => state.user.roles;
export const selectUsers = (state: RootState) => state.user.users;
export const selectUserLoading = (state: RootState) => state.user.loading;
export const selectUserError = (state: RootState) => state.user.error;

export default userSlice.reducer;
