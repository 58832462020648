import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchDashboardSummaryData } from "../../features/dashboard/dashboardSlice";
import { RootState, AppDispatch } from "../../store";
const OrderSummary = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { summaryData } = useSelector(
    (state: RootState) => state.dashboard
  );

  useEffect(() => {
    dispatch(fetchDashboardSummaryData());
  }, [dispatch]);

  const summary = summaryData && summaryData.length > 0 ? summaryData[0] : null;
  return (
    <div className="row">
      <div className="col-lg-6 col-md-6">
        <div className="panel panel-green">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-3">
                <i className="i-request"></i>
              </div>
              <div className="col-xs-9 text-right">
                <div className="huge" id="divTotalTreq">{summary?.totalTreq}</div>
                <div>Transportation Requests</div>
              </div>
            </div>
          </div>
          <Link to="/treq-listing">
            <div className="panel-footer">
              <span className="pull-left">View Details</span>
              <div className="clearfix"></div>
            </div>
          </Link>
        </div>
      </div>
      <div className="col-lg-6 col-md-6">
        <div className="panel panel-red">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-3">
                <i className="i-invoice2"></i>
              </div>
              <div className="col-xs-9 text-right">
                <div className="huge" id="divTodayTotalTreq">{summary?.todayTotalTreq}</div>
                <div>Today's Transportation Requests</div>
              </div>
            </div>
          </div>
          <Link to="/treq-listing">
            <div className="panel-footer">
              <span className="pull-left">View Details</span>
              <div className="clearfix"></div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
