import { useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';

export function useBlockNavigation(shouldBlock, message) {
    const navigate = useNavigate();


    useEffect(() => {
        const handleBeforeUnload = event => {
            if (!shouldBlock) return;
            event.preventDefault();
            event.returnValue = message;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [shouldBlock, message]);

    const confirmAndNavigate = (to, options = {}) => {
        if (!shouldBlock || window.confirm(message)) {
            navigate(to, options);
        }
    };

    return confirmAndNavigate;
}