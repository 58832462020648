import React, {ReactNode} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../utils/auth';
import {  UserRole } from '../constants/roles';

interface ProtectedRouteProps {
    allowedRoles?: UserRole[];
    children?: ReactNode
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedRoles, children }) => {
  const { isAuthenticated, user } = useAuth();
  const userRole = user?.role as UserRole;
  const location = useLocation();

  if (!isAuthenticated) {
      return <Navigate to="/login" replace state={{ from: location }} />;
  } else if (allowedRoles && !allowedRoles.includes(userRole)) {
      return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;