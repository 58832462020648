import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
  isVisible: boolean;
  title: string;
  message: string;
}

const initialState: ModalState = {
  isVisible: false,
  title: '',
  message: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state, action: PayloadAction<{ title: string; message: string }>) {
      state.isVisible = true;
      state.title = action.payload.title;
      state.message = action.payload.message;
    },
    hideModal(state) {
      state.isVisible = false;
      state.title = '';
      state.message = '';
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
