const initialState = {
    loading: {},
    errors: {}
  };
  
  function apiStatusReducer(state = initialState, action) {
    const { type, payload } = action;
    
    if (type.endsWith('/pending')) {
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
        errors: { ...state.errors, [type]: null }
      };
    } else if (type.endsWith('/fulfilled')) {
      return {
        ...state,
        loading: { ...state.loading, [type]: false }
      };
    } else if (type.endsWith('/rejected')) {
      return {
        ...state,
        loading: { ...state.loading, [type]: false },
        errors: { ...state.errors, [type]: payload }
      };
    }
  
    return state;
  }
  
  export default apiStatusReducer;