import React from 'react';
import { useField } from 'formik';

interface TextAreaFieldProps {
  label: string;
  name: string;
  rows?: number;
  placeholder?: string;
  readOnly?:boolean;
  maxlength?:number;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({ label, name, rows = 3, placeholder,readOnly=false,maxlength }) => {
  const [field, meta] = useField(name);

  return (
    <>
      <label htmlFor={name} className="control-label col-sm-1 fw-norm p-lr5">{label}</label>
      <div className="col-md-5">
        <textarea
          {...field}
          id={name}
          className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
          rows={rows}
          placeholder={placeholder}
          readOnly={readOnly}
          maxLength={maxlength}
        />
        {meta.touched && meta.error && (
          <div className="help-block error-msg">{meta.error}</div>
        )}
      </div>
    </>
  );
};

export default TextAreaField;
