import React, { useState } from "react";
import TreqForm from "../components/TreqForm";
import TreqTable from "../components/TreqTable";
import { fetchTreqData } from '../features/treq/treqSlice';
import { RootState } from '../store';

const TreqListingPage: React.FC = () => {
  const [filterParams, setFilterParams] = useState<{ startDate: string; endDate: string; treqId: string }>({ startDate: '', endDate: '', treqId: '' });
  const selector = (state: RootState) => state.treq;

  const handleFilterParams = (startDate: string, endDate: string, treqId: string) => {
    setFilterParams({ startDate, endDate, treqId });
  };

  return (
    <div className="page-content overflow">
      <div className="row">
        <div className="col-md-12">
          <h3 className="page-title">Home </h3>
        </div>
      </div>
      <hr className="hr" />
      <div className="row treqListing" style={{overflow:"hidden"}}>
        <TreqForm fetchData={fetchTreqData} setFilterParams={handleFilterParams} />
        <TreqTable fetchData={fetchTreqData} selector={selector} showPrintButton={true} filterParams={filterParams} />
      </div>
    </div>
  );
};

export default TreqListingPage;
