import React, { useState, useEffect,useCallback,useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMonthlyData } from '../../features/dashboard/dashboardSlice'; // Adjust based on your actual action
import { RootState, AppDispatch } from '../../store';
import GenericChartComponent from '../../components/GenericChartComponent';
import SelectComponent from '../../components/SelectComponent';
import { yearOptions } from '../../utils/date';
import Loading from "../../components/Loading"

const MonthlyChart = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { monthlyData,fetchMonthlyDataStatus } = useSelector((state: RootState) => state.dashboard); // Adjust based on your state
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());


  useEffect(() => {
     dispatch(fetchMonthlyData(selectedYear));
  }, [dispatch, selectedYear]);

  const handleYearChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(event.target.value);
}, []);



const chartData = useMemo(() => {
  const validData = Array.isArray(monthlyData) ? monthlyData : [];
  return {
    labels:  validData.map(data => data.monthName),
  datasets: [{
      label: `Monthly Count`,
      data:  validData.map(data => data.count),
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1
  }]
  };
}, [monthlyData]);


const chartOptions = useMemo(() => ({
  scales: {
      y: {
          beginAtZero: true
      }
  },
  responsive: true
}), []);

  return (
    <div>
      <h2>Treq's based on months</h2>
      <div className="col-sm-3" style={{ float: 'right' }}>
      <SelectComponent value={selectedYear} onChange={handleYearChange} options={yearOptions} title="Year" />
      </div>
      {fetchMonthlyDataStatus==='loading'?<Loading style={{height:'40vh',width:'100%'}} />:
      <GenericChartComponent type="bar" data={chartData} options={chartOptions} />
      }
    </div>
  );
};

export default MonthlyChart;
