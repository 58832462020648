import React, { useState,useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAuth } from "../utils/auth";
import { useDataFetch } from "../hooks/useDataFetch";
import { submitForm, submitAttachments } from "../features/formSubmissionSlice";
import { showNotification } from "../features/notification/notificationSlice";
import { RootState } from "../store";
import Loading from "../components/Loading";
import CommonModal from "../components/CommonModal";
import {
  initialValues as defaultInitialValues,
  getValidationSchema,
  initialValuesBrazilUser,
} from "../components/CreateTreq/formConfig";
import GeneralInformation from "../components/GeneralInformation";
import TransportationInfo from "../components/TransportationInfo";
import FlightInformation from "../components/FlightInformation";
import VehicleInformation from "../components/VehicleInformation";
import TravelInformation from "../components/TravelInformation";
import Attachment from "../components/Attachment";
import { createPayload } from "../models/createPayload";
import {useBlockNavigation} from "../hooks/useBlockNavigation"

const CreateTreq: React.FC = () => {
  const dispatch = useDispatch();
  const formSubmission = useSelector((state: RootState) => state.formSubmission);
  const { user } = useAuth();
  const isBrazilUser = user?.isBrazilUser;
  const { status } = useSelector((state: RootState) => state.formData);
  const location = useLocation();
  const clonedInitialValues = location.state?.initialValues;

  useDataFetch(isBrazilUser);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [isError, setIsError] = useState(false);
  const [forceSubmit, setForceSubmit] = useState(false);
  const [actionType, setActionType] = useState(2);
  const [isFormDirty, setIsFormDirty]=useState(false);
  const [countdown, setCountdown] = useState(10);
  const [showTimer, setShowTimer] = useState(false);

  useEffect(() => {
    let timer;
    if (showTimer && countdown > 0) {
      timer = setInterval(() => {
        setModalContent(<StatusMessage />);
        setCountdown(prevCountdown => prevCountdown - 1);
        
      }, 1000);
    } else if (countdown === 0) {
     window.close();
    }
    return () => clearInterval(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTimer, countdown]);
  const handleFormChange = () => {
    setIsFormDirty(true);
  };

    const StatusMessage = () => (

    <p className='status-message'>
      {actionType === 2 ? "Treq submitted successfully!" : "Treq saved successfully!"}
      <span>{(user?.SchedulinkInfo)?`This page will be closed after ${countdown} Seconds`:''}</span>
    </p>
  );

  useBlockNavigation(isFormDirty,"You have unsaved changes. Are you sure you want to leave?");

  const recommendedFields = isBrazilUser?["dateNeeded","timeNeeded", "projectManager","purposeoftrip"]:["passengers","parcels","dateNeeded","timeNeeded", "projectManager","purposeoftrip","pickupState","pickupCity","pickupLocation","deliveryState","deliveryCity","pickupContactName","contactTelephone"];
  const fieldNamesMap = {passengers:"No. Of Passengers",parcels:"No. Of Parcels",dateNeeded:"Date Needed",timeNeeded:"Time Needed", purposeoftrip: "Purpose of T-REQ", projectManager: "project manager",pickupState:"P/U State",pickupCity:"P/U City",pickupLocation:"P/U Location",deliveryState:"Delivery State",deliveryCity:"Delivery City",pickupContactName:"Name of Contact P/U Location",contactTelephone:"Contact Telephone #" };

  const checkRecommendedFields = (values: any) => recommendedFields.filter(field => !values[field]);

  const handleModalClose = () => {
    setModalVisible(false);
    if (!isError) 
    { 
      if (user?.SchedulinkInfo){
        window.close();
      }
      else {
      window.location.href = "/treq-listing";
      }
    }
  };

  const handleForceSubmit = async () => {
    setForceSubmit(true);
    setModalVisible(false);
    document.getElementById(actionType === 1 ? "force-save-button" : "force-submit-button")?.click();
  };


  const onSubmit = async (values: any) => {
    setIsFormDirty(false);
    const emptyRecommendedFields = checkRecommendedFields(values);
    if (emptyRecommendedFields.length > 0 && !forceSubmit) {
      setModalHeader("It is recommended to fill the below information:");
      setModalContent(<>
        <ul>
          {emptyRecommendedFields.map((field, index) => (
            <li key={index}>{fieldNamesMap[field]}</li>
          ))}
        </ul>
        <br />
        Do you really want to continue?
        </>
      );
      setIsError(true);
      setModalVisible(true);
      return;
    }
    setForceSubmit(true);

    const payload = createPayload(values, actionType, isBrazilUser,0);
    if (user?.SchedulinkInfo){
      payload.SchedulinkInfo=user?.SchedulinkInfo;
    }

    dispatch(submitForm(payload))
    .unwrap()
    .then((response) => {
      const treqId = response.requestId;
      const formData = new FormData();

      (values.attachments?.add || []).forEach((fileData: any) => {
        formData.append("files", fileData.file,fileData.file.name);
      });

      

      if (user?.SchedulinkInfo){
        setShowTimer(true)
      }
  

      if (values.attachments?.add && values.attachments?.add.length > 0) {
        dispatch(submitAttachments({ treqId, formData }))
          .unwrap()
          .then(() => {
            setModalHeader("Success");
            setModalContent(<StatusMessage key={countdown}/>);
            setIsError(false);
            setModalVisible(true);
          });
      } else {
        setModalHeader("Success");
        setModalContent(<StatusMessage key={countdown} />);
        setIsError(false);
        setModalVisible(true);
      }
    })
    .catch((error: any) => {
      setModalHeader("");
      setModalContent(`${error}. Please try again`);
      setIsError(true);
      setModalVisible(true);
    });
  };

  return (
    <div className="page-content overflow treq-table">
      {formSubmission.loading && (
        <div className="overlay">
          <div className="loading-icon"></div>
        </div>
      )}
      <h3 className="page-title">Create New T-Req</h3>
      <hr className="hr" />
      {status === "loading" && <Loading />}
      <Formik
        initialValues={clonedInitialValues || (isBrazilUser ? initialValuesBrazilUser : defaultInitialValues)}
        validationSchema={getValidationSchema(isBrazilUser)}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ validateForm }) => (
          <Form className="form-horizontal white content-border padding15 padding-btm5" onChange={handleFormChange}>
            {isBrazilUser ? (
              <>
                <GeneralInformation readOnly={false} treqId={null} dateNeeded="" dateSubmitted={null} isBrazilUser={true} edit={false} />
                <TransportationInfo />
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-9">
                    <GeneralInformation readOnly={false} treqId={null} dateNeeded="" dateSubmitted={null} isBrazilUser={false} edit={false}  />
                    <ErrorMessage name="projectId" component="div" className="error-message" />
                  </div>
                  <FlightInformation readOnly={false} />
                </div>
                <TravelInformation readOnly={false} />
                <VehicleInformation readOnly={false} />
              </>
            )}
            <Attachment />
            <div className="form-group btns">
              <div className="col-sm-10 mg-btm40">
                <button
                  type="submit"
                  className="btn icn-save"
                  id="force-save-button"
                  onClick={async () => {
                    setActionType(1);
                    const errors = await validateForm();
                    if (Object.keys(errors).length > 0) {
                      dispatch(showNotification({ message: "Please fill all the mandatory fields", type: "error" }));
                    }
                  }}
                  disabled={formSubmission.loading}
                >
                  Save
                </button>
                &nbsp;
                <button
                  type="submit"
                  className="btn icn-savesub"
                  id="force-submit-button"
                  onClick={async () => {
                    setActionType(2);
                    const errors = await validateForm();
                    if (Object.keys(errors).length > 0) {
                      dispatch(showNotification({ message: "Please fill all the mandatory fields", type: "error" }));
                    }
                  }}
                  disabled={formSubmission.loading}
                >
                  Submit
                </button>
                &nbsp;
                {!user?.SchedulinkInfo&&
                <button
                  type="button"
                  className="btn icn-cancel"
                  onClick={() => (window.location.href = "/treq-listing")}
                  disabled={formSubmission.loading}
                >
                  Cancel
                </button>}
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <CommonModal
        visible={modalVisible}
        header={modalHeader}
        content={
          <>
            {modalContent}
            {isError && !forceSubmit && (
              <div className="modal-footer">
                <button type="button" className="btn icn-save" id="btnContinue" onClick={handleForceSubmit}>
                  Continue
                </button>
                <button type="button" className="btn icn-cancel" data-dismiss="modal" onClick={handleModalClose}>
                  &nbsp;Cancel
                </button>
              </div>
            )}
          </>
        }
        onClose={handleModalClose}
        showCloseButton={!(isError && !forceSubmit)}
      />
    </div>
  );
};

export default CreateTreq;
