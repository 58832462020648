import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';
import { ENDPOINTS } from '../../api/config';
import createDataFetchThunk from "../../api/createDataFetchThunk";

export interface Group {
  id: string;
  name: string;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

interface GroupState {
  groups: Group[];
  groupMembers: User[];
  loading: boolean;
  groupLoading: boolean;
  error: string | null;
}

const initialState: GroupState = {
  groups: [],
  groupMembers: [],
  loading: false,
  groupLoading: false,
  error: null,
};

export const addGroup = createDataFetchThunk<Group, { groupName: string }>(
  'groups/addGroup',
  async (group) => {
    const response = await axiosInstance.post(ENDPOINTS.ADD_GROUP, group);
    return response.data;
  },2
);

export const editGroup = createDataFetchThunk<Group, { groupId: string; groupName: string }>(
  'groups/editGroup',
  async (group) => {
    const response = await axiosInstance.post(`${ENDPOINTS.ADD_GROUP}`, group);
    return response.data;
  },2
);

export const deleteGroup = createDataFetchThunk<{ groupId: string }, string>(
  'groups/deleteGroup',
  async ({ groupId }) => {
    await axiosInstance.delete(`${ENDPOINTS.DELETE_GROUP}?groupId=${groupId}`);
    return groupId;
  },
  2
);

export const fetchGroups = createDataFetchThunk<Group[], void>(
  'groups/fetchGroups',
  async () => {
    const response = await axiosInstance.get(ENDPOINTS.FETCH_GROUPS);
    return response.data;
  },
  2
);

export const fetchGroupMembers = createDataFetchThunk<User[], string>(
  'groups/fetchGroupMembers',
  async (groupId) => {
    const response = await axiosInstance.get(`${ENDPOINTS.FETCH_GROUP_MEMBERS}?groupId=${groupId}`);
    return response.data;
  },2
);

export const deleteGroupMembers = createDataFetchThunk<{ groupId: string; groupMemberId: string[] }, { groupId: string; groupMemberId: string[] }>(
  'groups/deleteGroupMembers',
  async ({ groupId, groupMemberId }) => {
    await axiosInstance.post(`${ENDPOINTS.DELETE_GROUP_MEMBERS}`, { groupMemberId,groupId });
    return { groupId, groupMemberId };
  },2
);

const groupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addCase(editGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addCase(deleteGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
       
        state.loading = false;
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchGroups.pending, (state) => {
        state.groupLoading = true;
        state.error = null;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.groups = action.payload;
        state.groupLoading = false;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.groupLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchGroupMembers.pending, (state) => {
        state.groupLoading = true;
        state.error = null;
      })
      .addCase(fetchGroupMembers.fulfilled, (state, action) => {
        state.groupLoading = false;
      })
      .addCase(fetchGroupMembers.rejected, (state, action) => {
        state.groupLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(deleteGroupMembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteGroupMembers.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteGroupMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      });
  },
});

export default groupSlice.reducer;