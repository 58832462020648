import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { hideNotification } from '../features/notification/notificationSlice';
import './Notification.css';

const Notification = () => {
  const dispatch = useDispatch();
  const { message, type, visible } = useSelector((state: RootState) => state.notification);

  if (!visible) return null;

  return (
    <div className={`notification-popup ${type}`}>
      <div className="notification-content">
        {typeof message === 'string' ? <p>{message}</p> : message}
      </div>
      <button onClick={() => dispatch(hideNotification())}>Close</button>
    </div>
  );
};

export default Notification;
