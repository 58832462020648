import React from "react";
import maintenanceImage from '../img/img-maintenance.png';
import maintenanceCrewImage from '../img/img-maintenance-crew.png';

function UnderMaintenancePage(): JSX.Element {
  return (
    <>
      <div className="maintenance" style={{ overflowY: 'auto', textAlign: "center" }}>
        <p className="h2 text-center">We will be back shortly.</p>
        <p className="h3 text-center">
          The app is temporarily unavailable due to a Disaster Recovery drill.
        </p>
        <div className="text-center position-relative">
          <img src={maintenanceImage} alt="Maintenance" />
          <div className="m-crew">
            <img src={maintenanceCrewImage} alt="Maintenance Crew" />
          </div>
        </div>
      </div>
    </>
  );
}

export default UnderMaintenancePage;
