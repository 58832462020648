import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { ChartData, ChartConfiguration, ChartType } from 'chart.js';

interface GenericChartProps {
    type: ChartType;
    data: ChartData;
    options: ChartConfiguration['options'];
}

const GenericChartComponent: React.FC<GenericChartProps> = ({ type, data, options }) => {
    const chartContainer = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const ctx = chartContainer.current?.getContext('2d');
        let chartInstance: Chart | null = null;

        if (ctx) {
            chartInstance = new Chart(ctx, {
                type,
                data,
                options
            });
        }

        return () => {
            chartInstance?.destroy();
        };
    }, [data, options, type]);

    return <canvas ref={chartContainer} className="canvas-chart"></canvas>;
};

export default GenericChartComponent;
