import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { hideModal } from '../features/modal/modalSlice';
import { logout } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import './SessionExpiredModal.css';

const SessionExpiredModal: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isVisible, title, message } = useSelector((state: RootState) => state.modal);

  const handleClose = () => {
    dispatch(hideModal());
    dispatch(logout());
    navigate('/login'); 
  };

  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h4>{title}</h4>
        <p>{message}</p>
        <button onClick={handleClose}>OK</button>
      </div>
    </div>
  );
};

export default SessionExpiredModal;
