import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import {  formatDateTime ,tranformData} from "../../src/utils/common";


interface FlightInformationDetailProps {
  data: any;
}

const FlightInformationDetail: React.FC<FlightInformationDetailProps> = ({ data }) => {
  const [airlines, setAirlines] = useState<{ id: string; value: string }[]>([]);
  const { udcType } = useSelector((state: RootState) => state.formData);

  useEffect(() => {
    if (udcType.length > 0) {
      const transformedAirlines = tranformData(udcType, 'Airline');
      setAirlines(transformedAirlines);
    }
  }, [udcType]);

  const getAirlineNameById = (id: string) => {
    const airline = airlines.find(air => air.id === id);
    return airline ? airline.value : id;
  };

  return (
    <div id="flightInfoId" className="form-horizontal white content-border padding15 col-md-3">
      <fieldset>
        <legend>Flight Information</legend>
        <div className="form-group">
          <label className="control-label col-sm-4 fw-norm p-lr5">Airline:</label>
          <div className="col-sm-6">
            <p className="form-control-static">{getAirlineNameById(data.airlineCode)}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4 fw-norm p-lr5">Flight #:</label>
          <div className="col-sm-6">
            <p className="form-control-static">{data.flightNumber}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4 fw-norm p-lr5">Airway Bill:</label>
          <div className="col-sm-6">
            <p className="form-control-static">{data.airwayBillNumber}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4 fw-norm p-lr5">Flt. Arr. Date Time:</label>
          <div className="col-sm-6">
            <p className="form-control-static">{formatDateTime(data.flightArrivaltime)}</p>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4 fw-norm p-lr5">Flt. Dep. Date Time:</label>
          <div className="col-sm-6">
            <p className="form-control-static">{formatDateTime(data.flightDeparturetime)}</p>
          </div>
        </div>

     
      </fieldset>
    </div>
  );
};

export default FlightInformationDetail;
